import * as React from 'react'
import { View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { AntDesign } from '@expo/vector-icons';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { Entypo, Ionicons } from '@expo/vector-icons';
import AdminDashboard from '../AdminDashboard';
import AdminReport from './AdminReport';
import AdminViewWallet from './admin/admin-view-wallet';
import AllProducts from './admin/products';
import Flyers from './admin/flyers';
import AdminReportPage from './admin/reportpage';
import PointsRequestApproval from './admin/points-request-approval';

const Tab = createBottomTabNavigator();

export default function AdminAppDashboard() {
    return (
        <Tab.Navigator initialRouteName='Home'>
            <Tab.Screen name="Home" options={{tabBarIcon: (props) => <AntDesign name="home" {...props} />}} component={AdminDashboard} />
            {/* <Tab.Screen name="Customer Reports" options={{tabBarIcon: (props) => <Entypo name="list" {...props} />}} component={AdminReport} /> */}
            <Tab.Screen name="Reports" options={{tabBarIcon: (props) => <Entypo name="yelp" {...props} />}} component={AdminReportPage} />
            <Tab.Screen name="Products" options={{unmountOnBlur: true, tabBarIcon: (props) => <MaterialCommunityIcons name="car-battery" {...props} />}} component={AllProducts} />
            <Tab.Screen name="Flyers" options={{tabBarIcon: (props) => <Ionicons name="mail-unread-outline" {...props} />}} component={Flyers} />
            <Tab.Screen name="Loyalty Points Request" options={{tabBarIcon: (props) => <MaterialIcons name="playlist-add-check" {...props} />}} component={PointsRequestApproval} />
        </Tab.Navigator>
    )
}