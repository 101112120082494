import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native'
import * as react from 'react'
import { FlatList, ScrollView, ToastAndroid, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { collection, doc, setDoc, getDocs, query, where, getDoc, orderBy } from "firebase/firestore";
import { database } from '../../firebase-app';
import Loader from '../../loader'
import { useDispatch } from 'react-redux';
import { setDefaultProduct, setLga, setPhoto, setState } from '../../defaultSlice';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Octicons } from '@expo/vector-icons';
import { useState } from 'react';
import { format } from 'date-fns';


export default function DraftCustomers() {
    let [customers, setCustomers] = react.useState([])
    let navigation = useNavigation();
    let [loading, setLoading] = react.useState(false)
    let [user, setUser] = useState({})
    let dispatcher = useDispatch();
    let [approvedSelected, setApprovedSelected] = react.useState(true)
    let [pendingApprovalSelected, setPendingApprovalSelected] = react.useState(true)
    function openCustomer(customer){
        navigation.replace('add-customer', {
            draftCustomer: customer
        })
    }
    async function loadUserProfile() {
        setLoading(true)
        let userData = await AsyncStorage.getItem('user')
        let userId = JSON.parse(userData).id;
        let _data = await getDoc(doc(database, "users", userId))
        setUser({
            ..._data.data(),
            id: userId
        })
        console.log(_data.data())
        setLoading(false)
    }
    function renderCustomer({ item }) {
        return (
            <MD3.Card style={{padding: 16, marginTop: 8}} onPress={() => {
                openCustomer(item)
            }}>
                <MD3.Caption>Customer Name</MD3.Caption>
                <MD3.Text>{item.fullName}</MD3.Text>
                <MD3.Caption>Added on</MD3.Caption>
                <MD3.Text>{format(item.addedOn.toDate(), 'dd MMM, y h:m a')}</MD3.Text>
                <Octicons style={{position: 'absolute', right: 0, top: 0}} name="dot-fill" size={24} color={MD3.MD2Colors.blueA400} />
            </MD3.Card>
        )
    }
    function addCustomer() {
        if(user?.status === "Approved"){
            dispatcher(setLga(null))
            dispatcher(setState(null))
            dispatcher(setPhoto({
                type: 'installation',
                url: null
            }))
            dispatcher(setPhoto({
                type: 'product',
                url: null
            }))
            dispatcher(setPhoto({
                type: 'sheet',
                url: null
            }))
            dispatcher(setDefaultProduct(null))
            navigation.navigate('add-customer')
        }else{
            ToastAndroid.show('Your account is not approved yet', ToastAndroid.SHORT)
        }
    }
    async function loadCustomers() {
        setLoading(true)
        let userData = await AsyncStorage.getItem('user')
        let user = JSON.parse(userData)
        let docs = await getDocs(query(collection(database, "drafts"), where("user", "==", user.id), orderBy("addedOn", "desc")))
        let _docs = [];
        docs.docs.forEach(doc => {
            let data = doc.data();
            _docs.push({
                ...data,
                id: doc.id
            })
            setCustomers(_docs)
        })
        setLoading(false)
    }
    useFocusEffect(() => {
        console.log("useFocusEffect")
    })
    react.useEffect(() => {
        loadCustomers();
        loadUserProfile();
    }, [])
    let filteredCustomers = customers.filter(e => {
        return (e.status === 'Pending' && pendingApprovalSelected) || (e.status === 'Approved' && approvedSelected)
    })
    let pendingApprovalCount = customers.filter(e => {
        return e.status === 'Pending'
    }).length
    let approvedCount = customers.filter(e => {
        return e.status === 'Approved'
    }).length
    return (
        <View style={{ flex: 1 }}>
            <FlatList
                data={[<View style={{ flex: 1, padding: 16 }}>
                    <FlatList
                        data={filteredCustomers}
                        renderItem={renderCustomer}
                        ListEmptyComponent={() => (
                            <MD3.Caption style={{  }}>No customers available</MD3.Caption>
                        )}
                        keyExtractor={item => `customer-${item.id}`} />
                </View>]}
                renderItem={({ item }) => item}
                keyExtractor={(item, idx) => `dataitem-${idx}`}
            />
            {loading && <Loader />}
        </View>
    )
}