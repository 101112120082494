import * as React from 'react'
import { Alert, ScrollView, Share, ToastAndroid, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation, useRoute } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { database } from '../../firebase-app';
import { doc, setDoc, getDoc, collection, query, where, getDocs, deleteDoc } from 'firebase/firestore';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Camera } from 'expo-camera';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ImagePicker from 'expo-image-picker'
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { manipulateAsync } from 'expo-image-manipulator';
import { setPhoto } from '../../defaultSlice';
import Loader from '../../loader';
import * as utils from '../../utils'
import { getImageUrl } from '../../utils';
import otpGenerators from 'otp-generators';
import * as Sharing from 'expo-sharing';
import axios from 'axios';
const storage = getStorage();


export default function ViewTechnician() {
    let [user, setUser] = React.useState({})
    let [loading, setLoading] = React.useState(false)
    let store = useSelector(state => state.default)
    let navigation = useNavigation()
    let route = useRoute();
    let dispatch = useDispatch();
    async function loadUserProfile() {
        console.log("*************")
        console.log(route.params.technician)
        setUser({
            ...route.params.technician
        })
    }
    async function saveUser() {
        setLoading(true);
        await setDoc(doc(database, "users", user.id), {
            ...user
        }, {
            merge: true
        })
        setLoading(false)
    }

    async function deleteUser(){
        if(confirm('Delete for sure?')){
            setLoading(true)
            await deleteDoc(doc(database, "users", user.id))
            setLoading(false)
            alert('Profile Deleted!!')
            location.reload()
        }
    }

    async function disableEnable(){
        setLoading(true)
        await setDoc(doc(database, "users", user.id), {
            disabled: !(!!user.disabled)
        }, {
            merge: true
        })
        setUser({
            ...user,
            disabled: !(!!user.disabled)
        })
        setLoading(false)
        navigation.pop(2)
        location.reload()
    }

    async function loginAs(){
        await AsyncStorage.setItem('user', JSON.stringify(user))
        navigation.replace('tenician-dashboard')
    }

    async function disapproveProfile() {
        setLoading(true);
        await setDoc(doc(database, "users", user.id), {
            status: 'Disapproved'
        }, {
            merge: true
        })
        setLoading(false)
        alert('Profile Disapproved!!')
        navigation.pop(2)
    }

    async function approveProfile() {
        setLoading(true);
        let _userDoc = await getDoc(doc(database, "users", user.id));
        let _userData = _userDoc.data();
        let newUserData = {
            ..._userData,
            id: _userDoc.id,
            status: 'Approved'
        }
        console.log("Updating", user)
        if (!!user.referralCode) {
            let _data = await getDocs(query(collection(database, "users"), where("referral", "==", user.referralCode)))
            if (_data.size >= 1) {
                let parent = _data.docs[0];
                let parentData = parent.data();
                console.log("Parent", parentData)
                console.log("Parent", parent.id)
                await setDoc(doc(database, "users", parent.id), {
                    ...parentData,
                    points: +(parentData.points || 0) + 25,
                    totalPoints: +(parentData.totalPoints || 0) + 25
                }, {
                    merge: true
                })
                console.log("Auditing Referrer")
                console.log(parentData)
                await utils.AUDITORS.auditPoints(JSON.parse(JSON.stringify(parentData)), 25, 'New referral ('+ newUserData.fullName +')', {'technician_name': newUserData.fullName})
                console.log("Setting user", user.id)
                console.log("74")
                utils.AUDITORS.auditPoints(JSON.parse(JSON.stringify(newUserData)), 15, 'Joining bonus referral (Referred by '+ parentData.fullName +' )', {})
                console.log("Auditing Child")
                console.log(newUserData)
                await setDoc(doc(database, "users", user.id), {
                    ...newUserData,
                    points: 15
                }, {
                    merge: true
                })
                let adminToken = await utils.getAdminToken()
                if (!!parentData.token) {
                    try {
                        utils.sendPush(parentData.token, "Your referral " + user.fullName + " has been approved!", "Approved referral!")
                    } catch (e) {
                        console.log("P1 Failed", e)
                    }
                }
                if (!!user.token) {
                    try {
                        utils.sendPush(user.token, "Your account has been approved!", "Account Approved!")
                    } catch (e) {
                        console.log("P2 Failed", e)
                    }
                }
                try {
                    // await axios.post('https://asia-south1-star-plus-battery.cloudfunctions.net/mail-util', {
                    //     "to": parentData.emailId,
                    //     "subject": "Star Plus Battery : New Referral",
                    //     "message": "Congratulations! A new member has joined the Loyalty program using your referral code"
                    // })
                    utils.sendEmail(parentData.emailId, "Star Plus Battery : New Referral", "Congratulations! A new member has joined the Loyalty program using your referral code");
                } catch (e) {
                    console.log("A1 Failed", e)
                }
            }else{
                console.log("106")
                await setDoc(doc(database, "users", user.id), {
                    status: 'Approved'
                }, {
                    merge: true
                })
                if (!!user.token) {
                    try {
                        utils.sendPush(user.token, "Your account has been approved!", "Account Approved!")
                    } catch (e) {
                        console.log("P2 Failed", e)
                    }
                }
            }
        } else {
            console.log("Setting", user.id)
            await setDoc(doc(database, "users", user.id), {
                status: 'Approved'
            }, {
                merge: true
            })
        }
        try {
            let res1 = await axios.post('https://asia-south1-star-plus-battery.cloudfunctions.net/mail-util', {
                "to": user.emailId,
                "subject": "Star Plus Battery : Approval",
                "message": "Congratulations! Your profile has been approved! Please collect your cap and T-shirt from Admin office.<br/>You can now start installing batteries at customer site and earn loyalty points.<br/>"
            })
            console.log(res1.data)
        } catch (e) {
            console.log("A2 Failed", e)
        }
        setLoading(false)
        alert('Approved successfully')
        navigation.pop(2)
    }
    React.useEffect(() => {
        loadUserProfile();
    }, []);
    return (
        <View style={{ flex: 1 }}>
            <View style={{ margin: 16, alignItems: 'center' }}>
                {user.profilePic ? <MD3.Avatar.Image size={96} source={{ uri: getImageUrl(user.profilePic) }} color='white' /> : <MD3.Avatar.Text size={96} label={user.username?.substring(0,2)?.toUpperCase()} color='white' />}
                <MD3.Title>{user.fullName}</MD3.Title>
                <MD3.Text>{user.emailId}&nbsp;&#9679;&nbsp;{user.username}</MD3.Text>
            </View>
            <View style={{ paddingHorizontal: 16 }}>
                <MD3.TextInput editable={false} value={user.address} onChangeText={value => {
                    setUser({
                        ...user,
                        address: value
                    })
                }} label={'Address'} returnKeyType='done' onSubmitEditing={saveUser} />
                <MD3.TextInput editable={false} value={user.altContact} onChangeText={value => {
                    setUser({
                        ...user,
                        altContact: value
                    })
                }} label={'Alternate contact number'} keyboardType='phone-pad' style={{ marginTop: 8 }} onSubmitEditing={saveUser} />
                <MD3.TextInput editable={false} value={user.city} onChangeText={value => {
                    setUser({
                        ...user,
                        city: value
                    })
                }} label={'City'} style={{ marginTop: 8 }} onSubmitEditing={saveUser} />
                <MD3.TextInput editable={false} value={user.state} label={'State'} style={{ marginTop: 8 }} onSubmitEditing={saveUser} />
                <MD3.TextInput editable={false} value={user.zip} label={'Zip Code'} style={{ marginTop: 8 }} onSubmitEditing={saveUser} />
                <View style={{flexDirection: 'row', justifyContent: 'space-evenly'}}>
                    {!user.status && <MD3.Button mode='outlined' style={{ borderRadius: 4, marginTop: 16 }} textColor='green' icon='shield' disabled={loading || user.status === 'Approved'}
                        onPress={approveProfile}> Approve</MD3.Button>}
                    {!user.status && <MD3.Button mode='outlined' style={{ borderRadius: 4, marginTop: 16 }} textColor='red' icon='shield' disabled={loading || user.status === 'Disapproved'}
                        onPress={deleteUser}> Disapprove</MD3.Button>}
                    {!!user.status && <MD3.Button mode='outlined' style={{ borderRadius: 4, marginTop: 16 }} textColor='green' icon='shield' disabled={loading || user.status != 'Approved'}
                        onPress={loginAs}> Login As</MD3.Button>}
                    {!!user.status && <MD3.Button mode='outlined' style={{ borderRadius: 4, marginTop: 16 }} textColor='green' icon='shield' disabled={loading}
                        onPress={disableEnable}> {user.disabled?'Enable':'Disable'}</MD3.Button>}
                    {!!user.status && <MD3.Button mode='outlined' style={{ borderRadius: 4, marginTop: 16 }} textColor='red' icon='shield' disabled={loading || user.status != 'Approved'}
                        onPress={deleteUser}> Delete</MD3.Button>}
                </View>
            </View>
            {loading && <Loader />}
        </View>
    )
}