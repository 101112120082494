import * as React from 'react'
import { Alert, FlatList, Image, ScrollView, ToastAndroid, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Camera } from 'expo-camera';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setPhoto } from '../../defaultSlice';
import Loader from '../../loader';
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, where } from 'firebase/firestore';
import { database } from '../../firebase-app';
import * as utils from '../../utils'
import { ADMIN_EMAIL, SLABS } from '../../utils';
import axios from 'axios';
import { Octicons } from '@expo/vector-icons';
import { useState } from 'react';

export default function RedeemPoints() {
    let [user, setUser] = React.useState({})
    let [redDialogVisible, setRedDialogVisible] = React.useState(false)
    let [loading, setLoading] = React.useState(false)
    let [redeemValue, setRedeemValue] = React.useState(0)
    let [history, setHistory] = useState([])
    function hideRedDialog() {
        setRedDialogVisible(false)
    }
    async function loadUser() {
        setLoading(true)
        let _data = await AsyncStorage.getItem('user')
        let _user = await getDoc(doc(database, "users", JSON.parse(_data).id));
        let _history = await getDocs(query(collection(database, "redeem_reqeusts"), where("requestedBy", "==", JSON.parse(_data).id), orderBy("timestamp", "desc")))
        console.log("Redeem count", _history.size, "for", JSON.parse(_data).id)
        setHistory(_history.docs.map(e => {
            return {
                ...e.data(),
                id: e.id
            }
        }).filter(e => e.status != "Rejected"))
        setUser({
            ..._user.data(),
            id: _user.id
        })
        setLoading(false)
    }
    async function requestRedemption() {
        setLoading(true)
        let requestId = uuidv4();
        let pendingRedeemptions = await getDocs(query(collection(database, "redeem_reqeusts"), where("requestedBy", "==", user.id), where("status", "in", ("Pending", "Approved by Accounts"))));
        if(pendingRedeemptions.size > 0){
            setLoading(false)
            alert("You already have pending redeem request!!")
            return;
        }
        await setDoc(doc(database, "redeem_reqeusts", requestId), {
            requestedBy: user.id,
            requestedPoints: redeemValue,
            nairaValue: redeemValue * 25,
            timestamp: new Date(),
            status: 'Pending',
            user: user
        })
        await setDoc(doc(database, "users", user.id), {
            blockedPoints: redeemValue
        }, {
            merge: true
        })
        let adminToken = await utils.getAdminToken()
        if (!!adminToken) {
            utils.sendPush(adminToken, "A new request for redeem point of " + redeemValue + " points has been received!", "Redeem Request!")
        }
        await axios.post('https://asia-south1-star-plus-battery.cloudfunctions.net/mail-util', {
            "to": ADMIN_EMAIL,
            "subject": "Star Plus Battery : Request for redemption",
            "message": `Dear Admin,<br/>Member ${user.fullName} has requested for a redeem of ${redeemValue * 1} points worth of ₦${redeemValue * 25}`
        })
        setLoading(false)
        setRedDialogVisible(false)
        ToastAndroid.show('Redeem request sent successfully', ToastAndroid.SHORT)
    }
    React.useEffect(() => {
        loadUser();
    }, [])
    let slab = SLABS.findSlab(user.points);
    return (
        <View style={{ padding: 16, flex: 1 }}>
            <MD3.Headline style={{ fontWeight: 'bold' }}>Hello {user.fullName?.split(" ")[0]}!</MD3.Headline>
            {/* {user.points <= 50 && <MD3.Caption>You are a Silver Member</MD3.Caption>}
            {(user.points >= 51 && user.points <= 250) && <MD3.Caption>You are a Gold Member</MD3.Caption>}
            {(user.points >= 251 && user.points <= 500) && <MD3.Caption>You are a Diamond Member</MD3.Caption>}
            {(user.points >= 501) && <MD3.Caption>You are a Platinum Member</MD3.Caption>} */}
            <MD3.Caption>You are a {slab?.label} Member</MD3.Caption>
            <MD3.Card style={{ marginVertical: 16, padding: 16 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {(slab?.label == 'Silver') && <Image source={require('./silver.png')} style={{ width: 48, height: 48 }} />}
                    {(slab?.label == 'Gold') && <Image source={require('./gold.png')} style={{ width: 48, height: 48 }} />}
                    {(slab?.label == 'Diamond') && <Image source={require('./diamond.png')} style={{ width: 48, height: 48 }} />}
                    {(slab?.label == 'Platinum') && <Image source={require('./platinum.png')} style={{ width: 48, height: 48 }} />}
                    <View style={{ flexDirection: 'row-reverse', flexGrow: 1, alignItems: 'baseline' }}>
                        <MD3.Text>&nbsp;Points</MD3.Text>
                        {/* <MD3.Text style={{fontWeight: 'bold', fontSize: 24}}>/{user.totalPoints || 0}</MD3.Text> */}
                        <MD3.Text style={{ fontWeight: 'bold', fontSize: 48 }}>{user.points || 0}</MD3.Text>
                    </View>
                </View>
            </MD3.Card>
            <MD3.Button disabled={(user.points || 0) === 0} mode='contained' icon='currency-ngn' onPress={() => setRedDialogVisible(true)}>Redeem Points</MD3.Button>
            <MD3.Caption style={{ fontWeight: 'bold', marginVertical: 8 }}>REDEEM HISTORY ({history.length})</MD3.Caption>
            <FlatList
                data={history}
                keyExtractor={(item, index) => `h-${index}`}
                renderItem={({ item }) => {
                    return <MD3.Card style={{ padding: 16 }}>
                        <MD3.Caption>Points</MD3.Caption>
                        <MD3.Text>{item.requestedPoints} (₦{item.requestedPoints * 25})</MD3.Text>
                        <MD3.Caption>Payment Mode</MD3.Caption>
                        <MD3.Text>{item.paymentMode}</MD3.Text>
                        <MD3.Caption>Date</MD3.Caption>
                        <MD3.Text>{format(item.timestamp.toDate(), 'd MMM, y')}</MD3.Text>
                        <Octicons style={{ position: 'absolute', right: 0, top: 0 }} name="dot-fill" size={24} 
                        color={item.status === 'Approved' ? MD3.MD2Colors.greenA700 : item.status === 'Rejected' ? MD3.MD2Colors.redA700 : MD3.MD2Colors.amberA700} />
                    </MD3.Card>
                }} />
            <MD3.Dialog visible={redDialogVisible} onDismiss={hideRedDialog} dismissable={false} style={{ backgroundColor: 'white' }}>
                <MD3.Dialog.Title>Redeem Points</MD3.Dialog.Title>
                <MD3.Dialog.Content>
                    <MD3.Caption>Existing points</MD3.Caption>
                    <MD3.Text>{user.points || 0}</MD3.Text>
                    <MD3.Caption>Redeem request for (min 1 point or ₦25)</MD3.Caption>
                    <MD3.Text>{redeemValue} points (₦{(redeemValue * 25).toFixed(2)})</MD3.Text>
                    {/* <Slider
                        step={1}
                        maximumValue={user.points || 0}
                        value={redeemValue}
                        onValueChange={setRedeemValue}
                    /> */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <MD3.Chip onPress={() => setRedeemValue((user.points || 0) * .1)}>10%</MD3.Chip>
                        <MD3.Chip onPress={() => setRedeemValue((user.points || 0) * .25)}>25%</MD3.Chip>
                        <MD3.Chip onPress={() => setRedeemValue((user.points || 0) * .5)}>50%</MD3.Chip>
                        <MD3.Chip onPress={() => setRedeemValue((user.points || 0) * 1)}>100%</MD3.Chip>
                    </View>
                </MD3.Dialog.Content>
                <MD3.Dialog.Actions>
                    <MD3.Button disabled={loading} onPress={hideRedDialog}>&nbsp;Dismiss&nbsp;</MD3.Button>
                    <MD3.Button onPress={() => {
                        requestRedemption()
                    }} mode='contained' icon='currency-ngn' disabled={loading || redeemValue <= 0} loading={loading} labelStyle={{ marginHorizontal: 16 }}>&nbsp;Submit Request&nbsp;</MD3.Button>
                </MD3.Dialog.Actions>
            </MD3.Dialog>
            {loading && <Loader />}
        </View>
    )
}