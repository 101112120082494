import { useNavigation } from '@react-navigation/native'
import * as React from 'react'
import { Image, ToastAndroid, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { database } from '../firebase-app'
import { collection, doc, setDoc, getDocs, query, where } from "firebase/firestore";
import Toast from 'react-native-toast-message';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios'
var newOTP = require('otp-generators')

const userRef = collection(database, "users");
export default function ResetPassword({ resetVisible, hideResetDialog }) {
    let [resetEmailId, setResetEmailId] = React.useState('')
    let [resetPassword, setResetPassword] = React.useState('')
    //let [resetVisible, setResetVisible] = React.useState(resetVisible);
    let [otp, setOtp] = React.useState('')
    let [sentOtp, setSentOtp] = React.useState('')
    let [loading, setLoading] = React.useState(false)
    let [otpSent, setOtpSent] = React.useState(false)
    let [otpVerified, setOtpVerified] = React.useState(false)
    let [userId, setUserId] = React.useState('')
    async function onSendOtp(){
        setLoading(true)
        let records = await getDocs(query(userRef, where("emailId", "==", resetEmailId)))
        console.log(records.docs)
        let _otp = newOTP.generate(6, { alphabets: false, upperCase: false, specialChar: false })
        setSentOtp(_otp)
        if(records.size > 0){
            setUserId(records.docs[0].id)
            await axios.post('https://asia-south1-star-plus-battery.cloudfunctions.net/mail-util', {
                "to": records.docs[0].data().emailId,
                "subject": "Star Plus Battery : Reset OTP",
                "message": "<div>Your reset password OTP is:</div><p><h3>" + _otp + "</h3></p>"
            })
            setOtpSent(true)
        }else{
            ToastAndroid.show('Invalid email address', ToastAndroid.SHORT)
        }
        setLoading(false)
    }
    async function onVerifyOtp(){
        if(otp === sentOtp){
            setOtpVerified(true)
        }else{
            ToastAndroid.show('Invalid OTP', ToastAndroid.SHORT)
        }
    }
    async function onResetPassword(){
        setLoading(true)
        await setDoc(doc(database, "users", userId),{
            password: resetPassword
        }, {
            merge: true
        })
        setLoading(false)
        ToastAndroid.show('OTP set successfully', ToastAndroid.SHORT)
        hideResetDialog();
    }
    function onBtnClicked(){
        if(otpSent){
            if(otpVerified){
                console.log("Reset")
                onResetPassword()
            }else{
                onVerifyOtp();
            }
        }else{
            onSendOtp()
        }
    }
    return (
        <MD3.Dialog visible={resetVisible} onDismiss={hideResetDialog} dismissable={false} style={{ backgroundColor: 'white' }}>
            <MD3.Dialog.Title>Reset Password</MD3.Dialog.Title>
            <MD3.Dialog.Content>
                <MD3.TextInput keyboardType='email-address' defaultValue={resetEmailId} onChangeText={setResetEmailId} disabled={loading || otpSent} label={'Enter Email ID'} mode='outlined' returnKeyType='next' onSubmitEditing={() => ref1.current?.focus()} blurOnSubmit={false} />
                {otpSent && !otpVerified && <MD3.TextInput defaultValue={otp} onChangeText={setOtp} disabled={loading || otpVerified} label={'Enter OTP'} mode='outlined' style={{ marginTop: 8 }} />}
                {otpVerified && <MD3.TextInput defaultValue={resetPassword} onChangeText={setResetPassword} disabled={loading} label={'Enter new password'} mode='outlined' secureTextEntry={true} style={{ marginTop: 8 }} />}
            </MD3.Dialog.Content>
            <MD3.Dialog.Actions>
                <MD3.Button disabled={loading} onPress={hideResetDialog}>&nbsp;Dismiss&nbsp;</MD3.Button>
                <MD3.Button onPress={() => {
                    onBtnClicked();
                }} mode='contained' icon='lock-open-variant' disabled={loading || (otpSent?(otpVerified && !resetPassword):!resetEmailId)} loading={loading} labelStyle={{ marginHorizontal: 16 }}>
                    &nbsp;{otpSent?(otpVerified?'Reset Password':'Verify Password'):'Request OTP'}&nbsp;
                </MD3.Button>
            </MD3.Dialog.Actions>
        </MD3.Dialog>
    )
}