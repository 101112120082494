import * as React from 'react'
import { Linking, ScrollView, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setLga } from '../../defaultSlice';
import { useDispatch } from 'react-redux';

export default function TechnicianProfile() {
    let navigation = useNavigation()
    let dispatch = useDispatch();
    async function logout() {
        await AsyncStorage.clear();
        navigation.replace('login')
    }
    function openFeedback() {
        navigation.navigate('feedback')
    }
    return (
        <ScrollView style={{ padding: 16 }} showsVerticalScrollIndicator={false}>
            <MD3.Caption>ACCOUNT</MD3.Caption>
            <View style={{ height: 16 }} />
            <MD3.List.Item onPress={() => {
                navigation.navigate('edit-profile')
            }} title='Profile Details' style={{ backgroundColor: 'white' }} left={(props) => <MD3.List.Icon icon={'account-outline'} {...props} />} />
            <MD3.Divider />
            <MD3.List.Item onPress={() => {
                dispatch(setLga(null))
                navigation.navigate('my-wallet')
            }} title='Reports' style={{ backgroundColor: 'white' }} left={(props) => <MD3.List.Icon icon={'seal'} {...props} />} />
            <MD3.Divider />
            <MD3.List.Item onPress={() => {
                navigation.navigate('redeem-points')
            }} title='Redeem Loyalty Points' style={{ backgroundColor: 'white' }} left={(props) => <MD3.List.Icon icon={'currency-ngn'} {...props} />} />
            <MD3.List.Item onPress={() => {
                navigation.navigate('loyalty-point-slab')
            }} title='Loyalty Points Slab' style={{ backgroundColor: 'white' }} left={(props) => <MD3.List.Icon icon={'podium-gold'} {...props} />} />
            <MD3.Divider />
            <MD3.List.Item onPress={() => {
                navigation.navigate('bank')
            }} title='Bank Account Details' style={{ backgroundColor: 'white' }} left={(props) => <MD3.List.Icon icon={'bank'} {...props} />} />
            <MD3.Divider />


            <View style={{ height: 16 }} />
            <MD3.Caption>POLICIES</MD3.Caption>
            <View style={{ height: 16 }} />
            <MD3.List.Item title='Frequently Asked Questions' style={{ backgroundColor: 'white' }} left={(props) => <MD3.List.Icon icon={'help'} {...props} />} onPress={() => {
                Linking.openURL('https://www.keepandshare.com/doc10/35722/faq-pdf-114k?da=y')
            }} />
            <MD3.Divider />
            <MD3.List.Item title='Terms & Conditions' style={{ backgroundColor: 'white' }} left={(props) => <MD3.List.Icon icon={'scale-balance'} {...props} />} onPress={() => {
                Linking.openURL('https://www.keepandshare.com/doc10/35725/terms-conditions-pdf-164k?da=y')
            }} />
            <MD3.Divider />
            <MD3.List.Item title='Privacy Policy' style={{ backgroundColor: 'white' }} left={(props) => <MD3.List.Icon icon={'account-outline'} {...props} />} onPress={() => {
                Linking.openURL('https://www.keepandshare.com/doc10/35724/privacy-policy-pdf-110k?da=y')
            }} />
            <MD3.Divider />
            <MD3.List.Item title='Contact Us' style={{ backgroundColor: 'white' }} left={(props) => <MD3.List.Icon icon={'phone'} {...props} />} onPress={() => {
                Linking.openURL('https://www.keepandshare.com/doc10/35723/contact-us-pdf-49k?da=y')
            }} />
            <MD3.List.Item title='Submit Feedback' style={{ backgroundColor: 'white' }} left={(props) => <MD3.List.Icon icon={'message-alert'} {...props} />} onPress={() => {
                openFeedback();
            }} />
            <MD3.Divider />
            <MD3.List.Item title='Logout' onPress={() => {
                logout();
            }} style={{ backgroundColor: 'white' }} left={(props) => <MD3.List.Icon icon={'account-outline'} {...props} />} />
            <MD3.Divider />
            <View style={{ height: 24 }} />
        </ScrollView>
    )
}