import * as React from 'react'
import { ScrollView, ToastAndroid, TouchableOpacity, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import {database} from './firebase-app'
import { collection, doc, setDoc, getDocs, query, where, getDoc, orderBy, deleteDoc } from "firebase/firestore"; 
import { uuid } from 'uuidv4';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import Loader from './loader';
import { setApprovals, setCustomers, setTechnicians } from './defaultSlice';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse } from 'date-fns';
import axios from 'axios';
import * as utils from './utils';
import { FlatList } from 'react-native';
import { useState } from 'react';
import { Ionicons } from '@expo/vector-icons';

const userRef = collection(database, "users");

export default function AdminDashboard() {
    let [accountCreationVisible, setAccountCreationVisible] = React.useState(false)
    let [username, setUsername] = React.useState('')
    let [password, setPassword] = React.useState('')
    let [loading, setLoading] = React.useState(false)
    let [activeApproval, setActiveApproval] = React.useState({})
    let [showApproval, setShowApproval] = React.useState(false)
    let [paymentMode, setPaymentMode] = useState('')
    let navigation = useNavigation();
    let dispatch = useDispatch();
    let store = useSelector(state => state.default)
    let ref1 = React.useRef()
    function hideDialog(){
        setShowApproval(false)
    }
    function hideDialog(){
        setUsername('')
        setPassword('')
        setAccountCreationVisible(false)
    }
    async function getDashboard(){
        setLoading(true)
        let customers = await getDocs(collection(database, "customers"))
        let technicians = await getDocs(query(collection(database, "users"), where("type", "!=", "Administrator")))
        let approvals = await getDocs(query(collection(database, "redeem_reqeusts"), orderBy("timestamp", "asc")))
        dispatch(setCustomers(customers.docs.map( e=> {
            return {
                ...e.data(),
                purchaseDate: e.purchaseDate?.toDate(),
                installationDate: e.installationDate?.toDate(),
                id: e.id
            }
        })))
        dispatch(setTechnicians(technicians.docs.map( e=> {
            return {
                ...e.data(),
                id: e.id
            }
        })))
        dispatch(setApprovals(approvals.docs.map( e=> {
            return {
                ...e.data(),
                id: e.id
            }
        }).filter(e => e.status != "Rejected")))
        setLoading(false)
    }
    async function onRegister(){
        setLoading(true);
        try{
            await setDoc(doc(database, "users", uuid()), {
                username: username,
                password: password,
                type: 'Technician'
            })
            ToastAndroid.show('Account created successfully', ToastAndroid.SHORT)
            hideDialog();
            setLoading(false)
        }catch(e){
            ToastAndroid.show('Could not create account', ToastAndroid.SHORT)
            setLoading(false)
        }
    }
    async function logout(){
        await AsyncStorage.clear();
        navigation.replace('login')
    }
    function openAllTechnicians(){
        navigation.navigate('admin-all-techs')
    }
    function dateFromSeconds(seconds){
        let d = seconds.toDate();
        return format(d, 'h:mm a, d MMM,y')
    }
    async function reject(){
        setLoading(true);
        await setDoc(doc(database, "redeem_reqeusts", activeApproval.id), {
            ...activeApproval,
            status: 'Rejected'
        }, {
            merge: true
        })
        let _user = await getDoc(doc(database, "users", activeApproval.requestedBy))
        let _userData = _user.data();
        await utils.AUDITORS.auditPoints(_userData, 0, 'Withdrawal Rejected (Points:'+activeApproval.requestedPoints+")", {})
        //await deleteDoc(doc(database, "redeem_reqeusts", activeApproval.id))
        let approvals = await getDocs(query(collection(database, "redeem_reqeusts"), orderBy("timestamp", "asc")))
        dispatch(setApprovals(approvals.docs.map( e=> {
            return {
                ...e.data(),
                id: e.id
            }
        })))
        setLoading(false)
        alert('Rejected!')
        hideDialog();
    }
    async function onApprove(){
        try{
            setLoading(true);
            await setDoc(doc(database, "redeem_reqeusts", activeApproval.id), {
                ...activeApproval,
                status: 'Approved',
                paymentMode
            }, {
                merge: true
            })
            let _user = await getDoc(doc(database, "users", activeApproval.requestedBy))
            let _userData = _user.data();
            await setDoc(doc(database, "users", activeApproval.requestedBy), {
                points: _userData.points - activeApproval.requestedPoints
            },{
                merge: true
            })
            let approvals = await getDocs(query(collection(database, "redeem_reqeusts"), orderBy("timestamp", "asc")))
            dispatch(setApprovals(approvals.docs.map( e=> {
                return {
                    ...e.data(),
                    id: e.id
                }
            })))
            let adminToken = await utils.getAdminToken()
            if(!!_userData.token){
                //utils.sendPush(adminToken, "Withdrawal has been approved for " + activeApproval.requestedPoints + " points", "Withdrawal Approved")
                
            }
            await utils.AUDITORS.auditPoints(_userData, -activeApproval.requestedPoints, 'Withdrawal ('+paymentMode+")", {})
            await axios.post('https://asia-south1-star-plus-battery.cloudfunctions.net/mail-util', {
                "to": activeApproval.user.emailId,
                "subject": "Star Plus Battery : Withdrawal Approved",
                "message": "<div>Approval of Redemption process is done. Please collect the amount from the Admin office</div>"
            })
            setLoading(false)
            setAccountCreationVisible(false)
            alert('Request Approved!')
        }catch(e){            
            setLoading(false)
            console.error(e)
            alert('Cannot approve right now! Try again later.')
        }
    }
    function approvalWorkflow(request){
        setActiveApproval(request)
        setAccountCreationVisible(true)
    }
    React.useEffect(() => {
        getDashboard()
    }, [])
    let pendingApprovals = store.approvals.filter(e => e.status === "Pending").slice(0,5)
    return (
        <View showsVerticalScrollIndicator={false} style={{ flexGrow: 1 }}>
            <View style={{ padding: 16, flex: 1 }}>
                <MD3.Caption style={{ marginBottom: 8 }}>STATISTICS</MD3.Caption>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <MD3.Card style={{ padding: 16, flex: 1, alignItems: 'center' }} onPress={() =>{
                        navigation.navigate('all-customers')
                    }}>
                        <MD3.Headline style={{ fontWeight: 'bold', textAlign: 'center' }}>{store.customers.length}</MD3.Headline>
                        <MD3.Text>Total Customers</MD3.Text>
                    </MD3.Card>
                    <View style={{ width: 16 }} />
                    <MD3.Card style={{ padding: 16, flex: 1, alignItems: 'center' }} onPress={openAllTechnicians}>
                        <MD3.Headline style={{ fontWeight: 'bold', textAlign: 'center' }}>{store.technicians.length}</MD3.Headline>
                        <MD3.Text>Total Technicians</MD3.Text>
                    </MD3.Card>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: 8, marginBottom: 8}}>
                    <MD3.Caption style={{ marginVertical: 8, textTransform: 'uppercase' }}></MD3.Caption>
                    <MD3.Chip onPress={getDashboard}>Refresh</MD3.Chip>
                </View>
                {/* {true && <FlatList
                    data={pendingApprovals}
                    keyExtractor={item => item.id}
                    showsVerticalScrollIndicator={false}
                    renderItem={({item}) => (
                        <View key={item.id}>
                            <MD3.Card style={{padding: 16}} onPress={() => approvalWorkflow(item)}>
                                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <View>
                                        <MD3.Text style={{fontWeight: 'bold'}}>{item.user.fullName}</MD3.Text>
                                        <MD3.Caption>{dateFromSeconds(item.timestamp)}</MD3.Caption>
                                    </View>
                                    <View style={{alignItems: 'flex-end'}}>
                                        <MD3.Title>{item.requestedPoints}</MD3.Title>
                                        <MD3.Caption>₦{item.nairaValue}</MD3.Caption>
                                    </View>
                                </View>
                            </MD3.Card>
                            <MD3.Divider/>
                        </View>
                    )}
                />} */}
                <View style={{flexGrow: 1}}/>
                <MD3.List.Item title='Logout' style={{ backgroundColor: 'white', marginTop: 16 }} left={(props) => <MD3.List.Icon icon={'lock'} {...props} />} onPress={logout}/>
            </View>
            <MD3.Dialog visible={accountCreationVisible} onDismiss={hideDialog} dismissable={true} style={{backgroundColor: 'white'}}>
                <MD3.Dialog.Title>
                    Pending Approval
                </MD3.Dialog.Title>
                <MD3.Dialog.Content>
                    <MD3.Caption>
                        Technician
                    </MD3.Caption>
                    <MD3.Text>{activeApproval.user?.fullName}</MD3.Text>
                    <MD3.Caption>
                        Requested Points
                    </MD3.Caption>
                    <MD3.Text>{activeApproval.requestedPoints}</MD3.Text>
                    <MD3.Caption>
                        Naira Value
                    </MD3.Caption>
                    <MD3.Text>₦{activeApproval.nairaValue}</MD3.Text>
                    <MD3.Caption>
                        Payment Mode
                    </MD3.Caption>
                    <MD3.TextInput value={paymentMode} onChangeText={setPaymentMode} label={'Enter Payment mode'} mode='outlined' dense/>
                </MD3.Dialog.Content>
                <MD3.Dialog.Actions>
                    {/* <MD3.Button disabled={loading} onPress={hideDialog}>&nbsp;Dismiss&nbsp;</MD3.Button> */}
                    <MD3.Button disabled={loading} onPress={reject}>&nbsp;Deny&nbsp;</MD3.Button>
                    <MD3.Button onPress={() => {
                        onApprove();
                    }} mode='contained' icon='lock' disabled={loading || !paymentMode} loading={loading} labelStyle={{ marginHorizontal: 16 }}>&nbsp;Approve Redemption&nbsp;</MD3.Button>
                </MD3.Dialog.Actions>
            </MD3.Dialog>
            {loading && <Loader/>}
        </View>
    )

}