import * as React from 'react'
import { Alert, FlatList, Image, ScrollView, ToastAndroid, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Loader from '../../loader';
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, where } from 'firebase/firestore';
import { database } from '../../firebase-app';
import utils, { ADMIN_EMAIL, SLABS } from '../../utils';
import axios from 'axios';
import { Octicons } from '@expo/vector-icons';
import { useState } from 'react';
import { useEffect } from 'react';
import { CSVLink } from 'react-csv';

export default function AdminViewAudit() {
    let [user, setUser] = React.useState({})
    let [redDialogVisible, setRedDialogVisible] = React.useState(false)
    let [loading, setLoading] = React.useState(false)
    let [redeemValue, setRedeemValue] = React.useState(0)
    let [referredBy, setReferredBy] = useState({})
    let [history, setHistory] = useState([])
    let navigation = useNavigation();
    let store = useSelector(state => state.default)
    function hideRedDialog() {
        setRedDialogVisible(false)
    }
    async function loadUser() {
        setLoading(true)
        let _data = store.defaultTechnician
        let _user = await getDoc(doc(database, "users", _data.id));
        let referredUser = null;
        if(!!_user.data().referralCode){
            console.log("Found Referral Code")
            let tRests = await getDocs(query(collection(database, "users"), where("referral", "==", _user.data().referralCode)))
            tRests.docs.map(e => {
                referredUser = {
                    id: e.id,
                    ...e.data()
                }
            })
            setReferredBy(referredUser)
            console.log("Found referral", referredUser)
        }else{
            console.log("User was not referred")
        }
        let _history = await getDocs(query(collection(database, "points_audit"), where("user.id", "==", _data.id), orderBy('timestamp', 'desc')))
        console.log("Redeem count", _history.size, "for", _data.id)
        setHistory(_history.docs.map(e => {
            return {
                ...e.data(),
                id: e.id
            }
        }))
        setUser({
            ..._user.data(),
            id: _user.id
        })
        setLoading(false)
    }
    function selectTechnician() {
        navigation.navigate('list-technician')
    }
    function getHistory() {
        let totalPoints = 0;
        let _history = history.map((e, idx) => {
            totalPoints = totalPoints + e.points;
            return {
                'Serial No': idx + 1,
                'Technician Name': e.user.fullName,
                'Points Earned By': e.reason,
                'Date': e.timestamp_str,
                'Customer Name': e.customer_name,
                'Referred Technician Name': (""+e.reason).includes('Joining bonus')?'':e.technician_name,
                'Referred By Technician Name': (""+e.reason).includes('Joining bonus')?referredBy.fullName:'',
                'No. of points earned': e.points,
                //'Naira Value': e.points * 25
            }
        })
        _history.push({
            'Referred Technician Name': 'Total',
            'No. of points earned': totalPoints
        })
        return _history;
    }
    useEffect(() => {
        if (!!store.defaultTechnician) {
            loadUser();
        }
    }, [store.defaultTechnician])

    let slab = SLABS.findSlab(user.points);
    return (
        <View style={{ padding: 16, flex: 1 }}>
            <MD3.Card style={{ padding: 16 }} onPress={selectTechnician}>
                <MD3.Caption>Select technician</MD3.Caption>
                <MD3.Text>{store?.defaultTechnician?.fullName}</MD3.Text>
            </MD3.Card>
            <MD3.Caption>{slab?.label} Member</MD3.Caption>
            <MD3.Card style={{ marginVertical: 8, padding: 16 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {(slab?.label == 'Silver') && <Image source={require('../technician/silver.png')} style={{ width: 48, height: 48 }} />}
                    {(slab?.label == 'Gold') && <Image source={require('../technician/gold.png')} style={{ width: 48, height: 48 }} />}
                    {(slab?.label == 'Diamond') && <Image source={require('../technician/diamond.png')} style={{ width: 48, height: 48 }} />}
                    {(slab?.label == 'Platinum') && <Image source={require('../technician/platinum.png')} style={{ width: 48, height: 48 }} />}
                    <View style={{ flexDirection: 'row-reverse', flexGrow: 1, alignItems: 'baseline' }}>
                        <MD3.Text>&nbsp;Points</MD3.Text>
                        <MD3.Text style={{ fontWeight: 'bold', fontSize: 48 }}>{user.points || 0}</MD3.Text>
                    </View>
                </View>
            </MD3.Card>
            <MD3.Caption style={{ fontWeight: 'bold', marginVertical: 8 }}>POINTS HISTORY ({history.length})</MD3.Caption>
            <View style={{ flexDirection: 'row' }}>
                <View style={{flex: 1, marginRight: 4}}>
                    <MD3.Text style={{ fontWeight: 'bold' }}>Customer Registration</MD3.Text>
                    <FlatList
                        data={history.filter(e => e.reason == "New customer registration")}
                        keyExtractor={(item, index) => `h-${index}`}
                        renderItem={({ item }) => {
                            return <MD3.Card style={{ padding: 16 }}>
                                <MD3.Caption>Points</MD3.Caption>
                                <MD3.Text>{item.points} (₦{item.points * 25})</MD3.Text>
                                <MD3.Caption>Date</MD3.Caption>
                                <MD3.Text>{item.timestamp_str}</MD3.Text>
                                <MD3.Caption>Remarks</MD3.Caption>
                                <MD3.Text>{item.reason}</MD3.Text>
                            </MD3.Card>
                        }} />
                </View>

                <View style={{flex: 1, marginLeft: 4}}>
                    <MD3.Text style={{ fontWeight: 'bold' }}>Referral</MD3.Text>
                    <FlatList
                        data={history.filter(e => e.reason != "New customer registration")}
                        keyExtractor={(item, index) => `h1-${index}`}
                        renderItem={({ item }) => {
                            return <MD3.Card style={{ padding: 16 }}>
                                <MD3.Caption>Points</MD3.Caption>
                                <MD3.Text>{item.points} (₦{item.points * 25})</MD3.Text>
                                <MD3.Caption>Date</MD3.Caption>
                                <MD3.Text>{item.timestamp_str}</MD3.Text>
                                <MD3.Caption>Remarks</MD3.Caption>
                                <MD3.Text>{item.reason}</MD3.Text>
                            </MD3.Card>
                        }} />
                </View>
            </View>
            <CSVLink data={getHistory()} target="_blank" filename='point-audit-history'>
                <MD3.FAB icon={'download'} style={{ position: 'absolute', right: 16, bottom: 16 }} onPress={() => {

                }} />
            </CSVLink>
            {loading && <Loader />}
        </View>
    )
}