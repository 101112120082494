import * as React from 'react'
import { Alert, Image, ScrollView, ToastAndroid, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Camera } from 'expo-camera';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setPhoto } from '../../defaultSlice';
import Loader from '../../loader';
import { useState } from 'react';
import * as utils from '../../utils'
import { ADMIN_EMAIL } from '../../utils';
import { useEffect } from 'react';
import axios from 'axios';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { database } from '../../firebase-app';
import Toast from 'react-native-root-toast';

export default function Flyers() {
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState('')
    let [status, setStatus] = useState('')
    const [message, setMessage] = useState('')
    const [comment, setComment] = useState('')
    let [user, setUser] = React.useState({})
    let [dialogVisible, setDialogVisible] = useState(false)
    let navigation = useNavigation()
    async function loadProfile(){
        let userData = await AsyncStorage.getItem('user')
        setUser(JSON.parse(userData));
    }
    useEffect(() => {
        loadProfile();
    }, [])
    async function submitFeedback(){
        setLoading(true)
        setDialogVisible(true)
        setStatus('Loading eligible technicians')
        let docs = await getDocs(query(collection(database, "users"), where("type", "==", "Technician")))
        let _docs = [];
        docs.docs.forEach(doc => {
            let data = doc.data();
            if(!!data.token){
                _docs.push({
                    ...data,
                    id: doc.id
                })
            }
        })
        if(_docs.length === 0){
            utils.showToast(Toast, "No technicians available")
            setDialogVisible(false)
            setLoading(false)
            return;
        }else{
            setStatus('Found ' + _docs.length + ' eligible technicians\nSending message\n0/'+_docs.length)
        }
        for(var i = 0; i < _docs.length; i++){
            let doc = _docs[i]
            try{
                await utils.sendPush(doc.token, message, title)
            }catch(e){
                console.error(e)
            }
            setStatus('Found ' + _docs.length + ' eligible technicians\nSending message\n'+(i+1)+'/'+_docs.length)
        }
        utils.showToast(Toast, "Flyers sent successfully")
        setDialogVisible(false)
        setLoading(false)
    }
    return (
        <View style={{flex: 1, padding: 16}}>
            <MD3.Card style={{padding: 16}}>
                <MD3.TextInput value={title} onChangeText={setTitle} editable={!loading} placeholder={'Title'} label='Title' mode='outlined'></MD3.TextInput>
                <MD3.TextInput value={message} numberOfLines={3} multiline editable={!loading} onChangeText={setMessage} label={'Message'} style={{marginTop: 8}} mode='outlined'></MD3.TextInput>
            </MD3.Card>
            <MD3.Button onPress={submitFeedback} disabled={!title || !message} mode='contained' style={{marginTop: 16}}>Send Notification</MD3.Button>
            <MD3.Dialog visible={dialogVisible} onDismiss={() => setDialogVisible(false)}>
                <MD3.Dialog.Title>Sending Push</MD3.Dialog.Title>
                <MD3.Dialog.Content>
                    <MD3.Paragraph>{status}</MD3.Paragraph>
                </MD3.Dialog.Content>
            </MD3.Dialog>
            {/* {loading && <Loader/>} */}
        </View>
    )
}