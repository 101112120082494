import * as React from 'react'
import { Alert, ScrollView, ToastAndroid, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation, useRoute } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { database } from '../firebase-app';
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Camera } from 'expo-camera';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format, parse } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ImagePicker from 'expo-image-picker'
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { manipulateAsync } from 'expo-image-manipulator';
import { setDefaultCustomer, setLga, setPhoto, setSelectedProduct, setState as setLgaState } from '../defaultSlice';
import Loader from '../loader';
import axios from 'axios';
import { Feather } from '@expo/vector-icons';
import * as utils from '../utils';
import { ADMIN_EMAIL, validPhoneNumber } from '../utils';
import { useEffect } from 'react';
const storage = getStorage();

export default function AddCustomer() {

    let ref1 = React.useRef();
    let ref2 = React.useRef();
    let ref3 = React.useRef();
    let ref4 = React.useRef();
    let ref5 = React.useRef();
    let ref6 = React.useRef();
    let ref7 = React.useRef();
    let ref8 = React.useRef();
    let ref9 = React.useRef();
    let ref10 = React.useRef();
    let ref11 = React.useRef();

    let [shwPurchaseDate, setShowPurchaseDate] = React.useState()
    let [shwInstallationDate, setShowInstallationDate] = React.useState()

    let store = useSelector(state => state.default);

    let navigation = useNavigation()
    let route = useRoute()
    let [loading, setLoading] = React.useState(false)
    let [purchaseDate, setPurchaseDate] = React.useState(new Date())
    let [installationDate, setInstallationDate] = React.useState(new Date())
    let dispatch = useDispatch();
    let draftCustomer = route.params?.draftCustomer;
    let [state, setState] = React.useState({
        fullName: '',
        firstName: '',
        lastName: '',
        fullAddress: '',
        zipCode: '',
        mobileNumber: '',
        emailId: '',
        productCode: '',
        serialNumber: '',
        storeName: '',
        lga: '',
        invoiceNumber: '',
        purchaseDate: '',
        installationDate: ''
    })
    function setValue(data) {
        setState({
            ...state,
            ...data
        })
    }

    useEffect(() =>{
        if(!!store.defaultCustomer){
            //console.log("Setting up defaultCustomer", store.defaultCustomer)
            setState({
                ...state,
                fullName: store.defaultCustomer?.fullName,
                firstName: store.defaultCustomer?.firstName,
                lastName: store.defaultCustomer?.lastName,
                fullAddress: store.defaultCustomer?.fullAddress,
                zipCode: store.defaultCustomer?.zipCode,
                mobileNumber: store.defaultCustomer?.mobileNumber,
                emailId: store.defaultCustomer?.emailId,
                serialNumber: store.defaultCustomer?.fullName,
                lga: store.defaultCustomer?.lga,
            })
            dispatch(setLgaState(store.defaultCustomer?.lga?.split(", ")[0]))
            dispatch(setLga(store.defaultCustomer?.lga?.split(", ")[1]))
        }else{
            if(!!draftCustomer && draftCustomer?.id){
                console.log("Draft Customer", draftCustomer.serialNumber)
                setState({
                    fullName: draftCustomer.fullName,
                    firstName: draftCustomer.firstName,
                    lastName: draftCustomer.lastName,
                    fullAddress: draftCustomer.fullAddress,
                    zipCode: draftCustomer.zipCode,
                    mobileNumber: draftCustomer.mobileNumber,
                    emailId: draftCustomer.emailId,
                    productCode: draftCustomer.productCode,
                    storeName: draftCustomer.storeName,
                    lga: draftCustomer.lga,
                    invoiceNumber: draftCustomer.invoiceNumber,
                    purchaseDate: parse(draftCustomer.purchaseDate, 'y-MM-dd', new Date()),
                    installationDate: parse(draftCustomer.installationDate, 'y-MM-dd', new Date()),
                    serialNumber: draftCustomer.serialNumber
                })
                if(!!draftCustomer.lga){
                    let [_lgaState, _lga] = draftCustomer.lga.split(", ")
                    dispatch(setLgaState(_lgaState))
                    dispatch(setLga(_lga))
                }
                if(!!draftCustomer.picInstallation){
                    dispatch(setPhoto({
                        type:  'installation',
                        url: draftCustomer.picInstallation
                    }))
                }
                if(!!draftCustomer.picProduct){
                    dispatch(setPhoto({
                        type:  'product',
                        url: draftCustomer.picProduct
                    }))
                }
                if(!!draftCustomer.picSheet){
                    dispatch(setPhoto({
                        type:  'sheet',
                        url: draftCustomer.picSheet
                    }))
                }
                if(!!draftCustomer.purchaseDate){
                    setPurchaseDate(parse(draftCustomer.purchaseDate, 'y-MM-dd', new Date()));
                }
                if(!!draftCustomer.installationDate){
                    setInstallationDate(parse(draftCustomer.installationDate, 'y-MM-dd', new Date()));
                }
                if(!!draftCustomer.productCode){
                    dispatch(setSelectedProduct(draftCustomer.productCode))
                }
            }else{
                setState({
                    fullName: '',
                    firstName: '',
                    lastName: '',
                    fullAddress: '',
                    zipCode: '',
                    mobileNumber: '',
                    emailId: '',
                    productCode: '',
                    serialNumber: '',
                    storeName: '',
                    lga: '',
                    invoiceNumber: '',
                    purchaseDate: '',
                    installationDate: ''
                })
                dispatch(setLgaState(''))
                dispatch(setLga(''))
            }
        }
    }, [store.defaultCustomer])

    async function saveCustomer() {
        if(state.mobileNumber && !validPhoneNumber(state.mobileNumber)){
            ToastAndroid.show('Invalid phone number', ToastAndroid.SHORT)
            return;
        }
        let customerId = uuidv4();
        setLoading(true)
        let userData = await AsyncStorage.getItem('user')
        let user = JSON.parse(userData)
        //Check for uniqueness
        let slNo = await getDocs(query(collection(database, "customers"), where("serialNumber", "==", state.serialNumber)))
        if(slNo.size > 0){
            alert('Serial Number already used!')
            setLoading(false)
            return;
        }
        if(user.username === state.mobileNumber){
            alert('Customer phone number cannot be same as your phone number!')
            setLoading(false)
            return;
        }
        await setDoc(doc(database, "customers", customerId), {
            ...state,
            ...store.defaultProduct,
            lga: store.selectedState + ', ' + store.selectedLga,
            picInstallation: store.picInstallation,
            fullName: state.firstName + ' ' + state.lastName,
            picProduct: store.picProduct,
            picSheet: store.picSheet,
            purchaseDate,
            installationDate: format(installationDate, 'y-MM-dd'),
            __installationDate: installationDate,
            purchaseDate: format(purchaseDate, 'y-MM-dd'),
            user: user.id,
            technician: user,
            status: 'Pending',
            serialNumber: state.serialNumber,
            productCode: store.defaultSelectedProduct
        })
        if(!!draftCustomer){
            await deleteDoc(doc(database, "drafts", draftCustomer.id))
        }
        let adminToken = await utils.getAdminToken()
        if(!!adminToken){
            utils.sendPush(adminToken, "New installation done by " + user.fullName, "New Installation!")
        }
        await axios.post('https://asia-south1-star-plus-battery.cloudfunctions.net/mail-util', {
            "to": ADMIN_EMAIL,
            "subject": "Star Plus Battery : New Installation",
            "message": "<div>New Installation registered</div><div>Technician Name: " + user.fullName + "</div><br/>Please approve!"
        })
        setLoading(false)
        ToastAndroid.show('Customer created!', ToastAndroid.SHORT)
        navigation.goBack()
    }

    async function saveDraft(){
        if(state.mobileNumber && !validPhoneNumber(state.mobileNumber)){
            ToastAndroid.show('Invalid phone number', ToastAndroid.SHORT)
            return;
        }
        let customerId = !!draftCustomer ? draftCustomer.id:uuidv4();
        setLoading(true)
        let userData = await AsyncStorage.getItem('user')
        let user = JSON.parse(userData)
        await setDoc(doc(database, "drafts", customerId), {
            ...state,
            ...store.defaultProduct,
            lga: store.selectedState + ', ' + store.selectedLga,
            picInstallation: store.picInstallation,
            fullName: state.firstName + ' ' + state.lastName,
            picProduct: store.picProduct,
            picSheet: store.picSheet,
            purchaseDate,
            installationDate: format(installationDate, 'y-MM-dd'),
            purchaseDate: format(purchaseDate, 'y-MM-dd'),
            user: user.id,
            technician: user,
            status: 'Pending',
            serialNumber: state.serialNumber,
            productCode: store.defaultSelectedProduct||'',
            addedOn: new Date()
        })
        setLoading(false)
        ToastAndroid.show('Draft saved!', ToastAndroid.SHORT)
        navigation.goBack()
    }

    function openLGA() {
        navigation.navigate('select-state')
    }

    let [focusedInput, setFocusedInput] = React.useState(0)

    async function uploadGalleryPhoto(photo, type) {
        setLoading(true)
        let height = photo.height;
        let width = photo.width;
        let expectedHeight = Math.min(1080, height);
        let expectedWidth = (width / height) * expectedHeight;
        let image = await manipulateAsync(photo.uri, [{
            resize: {
                width: expectedWidth,
                height: expectedHeight
            }
        }], {
            compress: 0.75,
            base64: true
        })
        let storageRef = ref(storage, type + '/' + uuidv4() + ".jpg");
        let f = await fetch(image.uri)
        let blob = await f.blob()
        let snapshot = await uploadBytes(storageRef, blob)
        dispatch(setPhoto({
            type: type,
            url: snapshot.metadata.fullPath
        }))
        setLoading(false)
    }

    function pickImage(type) {
        ImagePicker.getMediaLibraryPermissionsAsync().then(permission => {
            if (permission.granted) {
                ImagePicker.launchImageLibraryAsync({
                    quality: 0.75,
                    allowsMultipleSelection: false
                }).then(media => {
                    uploadGalleryPhoto(media, type)
                })
            }
        })
    }

    function takePicture(type) {
        Alert.alert('Select photo', 'How do you want to upload the photo?', [{
            text: 'Gallery',
            onPress: () => {
                pickImage(type)
            }
        }, {
            text: 'Camera',
            onPress: () => {
                navigation.navigate('take-photo', {
                    type
                })
            }
        }],{
            cancelable: true
        })
    }
    function openPurchaseDate() {
        setShowPurchaseDate(true)
    }
    function openInstallationDate() {
        setShowInstallationDate(true)
    }
    function selectProduct(){
        navigation.navigate('list-products')
    }
    function searchCustomer() {
        navigation.navigate('list-customer')
    }

    useEffect(() => {
        dispatch(setDefaultCustomer(null))
    }, [])

    return (
        <View>
            <ScrollView style={{ margin: 16 }} showsVerticalScrollIndicator={false}>
                <View style={{ padding: 16, backgroundColor: 'white', borderRadius: 8 }}>
                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <MD3.Title style={{ fontWeight: 'bold' }}>Installation Registration</MD3.Title>
                        <Feather name="search" size={18} style={{padding: 4}} color="black" onPress={searchCustomer}/>
                    </View>
                    <MD3.Subheading>Describe customer details along with product information</MD3.Subheading>
                    <MD3.TextInput disabled={loading} editable={!loading} value={state.firstName} onChangeText={value => {
                        setValue({
                            'firstName': value
                        })
                    }} label='Customer first name' mode='outlined' style={{ marginTop: 8 }} returnKeyType='next' autoFocus={focusedInput === 0} blurOnSubmit={false} onSubmitEditing={() => ref1.current?.focus()} />
                    <MD3.TextInput disabled={loading} editable={!loading} value={state.lastName} onChangeText={value => {
                        setValue({
                            'lastName': value
                        })
                    }} label='Customer last name' mode='outlined' ref={ref1} style={{ marginTop: 8 }} returnKeyType='next' blurOnSubmit={false} onSubmitEditing={() => ref2.current?.focus()} />
                    
                    <MD3.TextInput disabled={loading} editable={!loading} value={state.fullAddress} onChangeText={value => {
                        setValue({
                            'fullAddress': value
                        })
                    }} label='Full address' numberOfLines={3} multiline mode='outlined' style={{ marginTop: 8 }} ref={ref2} returnKeyType='next' />
                    {/* <MD3.TextInput disabled={loading} editable={!loading} value={state.zipCode} onChangeText={value => {
                        setValue({
                            'zipCode': value
                        })
                    }} label='Zip Code' mode='outlined' style={{ marginTop: 8 }} ref={ref2} returnKeyType='next' autoFocus={focusedInput === 2} blurOnSubmit={false} onSubmitEditing={() => ref3.current?.focus()} /> */}
                    <View style={{ marginTop: 8 }}>
                        <MD3.TextInput disabled={loading} editable={!loading} value={state.mobileNumber} onChangeText={value => {
                            setValue({
                                'mobileNumber': value
                            })
                        }} label='Mobile Number' style={{paddingStart: 42}} maxLength={10} mode='outlined' ref={ref3} keyboardType='phone-pad' returnKeyType='next' blurOnSubmit={false} onSubmitEditing={() => ref4.current?.focus()} />
                        <MD3.Text style={{position: 'absolute', fontSize: 15.5, left: 0, paddingVertical: 21, paddingStart: 16}}>+234</MD3.Text>
                    </View>
                    <MD3.TextInput disabled={loading} editable={!loading} value={state.emailId} onChangeText={value => {
                        setValue({
                            'emailId': value
                        })
                    }} label='Email Id' mode='outlined' style={{ marginTop: 8 }} ref={ref4} returnKeyType='next' keyboardType='email-address' blurOnSubmit={false} onSubmitEditing={() => ref5.current?.focus()} />
                    {/* <MD3.TextInput disabled={loading} editable={!loading} value={state.productCode} onChangeText={value => {
                        setValue({
                            'productCode': value
                        })
                    }} label='Product Code' mode='outlined' style={{ marginTop: 8 }} ref={ref5} returnKeyType='next' blurOnSubmit={false} onSubmitEditing={() => ref6.current?.focus()} /> */}
                    <View style={{ marginTop: 8 }}>
                        <MD3.Caption>Product Code</MD3.Caption>
                        <TouchableOpacity onPress={selectProduct} style={{ backgroundColor: MD3.MD3Colors.primary99, paddingVertical: 10, paddingHorizontal: 16, borderRadius: 4, borderColor: MD3.MD3Colors.neutral50, borderWidth: 1 }}>
                            <MD3.Subheading>{store.defaultSelectedProduct ? `${store.defaultSelectedProduct}` : 'Product Code'}</MD3.Subheading>
                        </TouchableOpacity>
                    </View>
                    {/* <MD3.TextInput disabled={loading} editable={false} value={store.defaultProduct?.serialNumber} label='Product Serial Number' mode='outlined' style={{ marginTop: 8 }} ref={ref6} returnKeyType='next' blurOnSubmit={false} onSubmitEditing={() => ref7.current?.focus()} /> */}
                    <MD3.TextInput disabled={loading} editable={!loading} value={state.serialNumber} keyboardType='numeric' onChangeText={value => {
                        setValue({
                            'serialNumber': value
                        })
                    }} label='Product Serial Number' mode='outlined' style={{ marginTop: 8 }} ref={ref6} returnKeyType='next' blurOnSubmit={false} onSubmitEditing={() => ref7.current?.focus()} />

                    <MD3.TextInput disabled={loading} editable={!loading} value={state.storeName} onChangeText={value => {
                        setValue({
                            'storeName': value
                        })
                    }} label='Dealer Store name (Optional)' mode='outlined' style={{ marginTop: 8 }} ref={ref7} returnKeyType='next' blurOnSubmit={false} onSubmitEditing={() => ref8.current?.focus()} />
                    {/* <MD3.TextInput disabled={loading} editable={!loading} value={state.lga} onChangeText={value => {
                        setValue({
                            'lga':value
                        })
                    }} label='LGA' mode='outlined' style={{marginTop: 8}} ref={ref8} returnKeyType='next' blurOnSubmit={false} onSubmitEditing={() => ref9.current?.focus()} /> */}
                    <View style={{ marginTop: 8 }}>
                        <MD3.Caption>LGA</MD3.Caption>
                        <TouchableOpacity onPress={openLGA} style={{ backgroundColor: MD3.MD3Colors.primary99, paddingVertical: 10, paddingHorizontal: 16, borderRadius: 4, borderColor: MD3.MD3Colors.neutral50, borderWidth: 1 }}>
                            <MD3.Subheading>{(store.selectedState && store.selectedLga) ? `${store.selectedLga}, ${store.selectedState}` : 'Select State'}</MD3.Subheading>
                        </TouchableOpacity>
                    </View>
                    {shwPurchaseDate && <RNDateTimePicker mode="date" value={purchaseDate} maximumDate={new Date()} onChange={(event, date) => {
                        if (!!date) {
                            setShowPurchaseDate(false)
                            setPurchaseDate(date)
                        }
                    }} />}
                    {shwInstallationDate && <RNDateTimePicker minimumDate={purchaseDate} mode="date" value={installationDate} maximumDate={new Date()} onChange={(event, date) => {
                        if (!!date) {
                            setShowInstallationDate(false)
                            setInstallationDate(date)
                        }
                    }} />}
                    <MD3.TextInput disabled={loading} editable={!loading} value={state.invoiceNumber} onChangeText={value => {
                        setValue({
                            'invoiceNumber': value
                        })
                    }} label='Invoice Number (Optional)' mode='outlined' style={{ marginTop: 8 }} ref={ref9} />
                    <View style={{ marginTop: 8 }}>
                        <MD3.Caption>Purchase Date</MD3.Caption>
                        <TouchableOpacity onPress={openPurchaseDate} style={{ backgroundColor: MD3.MD3Colors.primary99, paddingVertical: 10, paddingHorizontal: 16, borderRadius: 4, borderColor: MD3.MD3Colors.neutral50, borderWidth: 1 }}>
                            {/* <MD3.Subheading>{(store.selectedState && store.selectedLga)?`${store.selectedLga}, ${store.selectedState}`:'Select LGA'}</MD3.Subheading> */}
                            <MD3.Subheading>{!!purchaseDate ? format(purchaseDate, 'd MMM, Y') : 'Select Purchase Date'}</MD3.Subheading>
                        </TouchableOpacity>
                    </View>
                    <View style={{ marginTop: 8 }}>
                        <MD3.Caption>Created on</MD3.Caption>
                        <TouchableOpacity onPress={openInstallationDate} style={{ backgroundColor: MD3.MD3Colors.primary99, paddingVertical: 10, paddingHorizontal: 16, borderRadius: 4, borderColor: MD3.MD3Colors.neutral50, borderWidth: 1 }}>
                            {/* <MD3.Subheading>{(store.selectedState && store.selectedLga)?`${store.selectedLga}, ${store.selectedState}`:'Select LGA'}</MD3.Subheading> */}
                            <MD3.Subheading>{!!installationDate ? format(installationDate, 'd MMM, Y') : 'Select Installation Date'}</MD3.Subheading>
                        </TouchableOpacity>
                    </View>

                    <MD3.Button mode='outlined' style={{ borderRadius: 4, marginTop: 16 }} icon={!!store.picInstallation ? 'check' : 'camera'} onPress={() => takePicture('installation')}>{!!store.picInstallation && 'Retake'} Picture of Installation</MD3.Button>
                    <MD3.Button mode='outlined' style={{ borderRadius: 4, marginTop: 16 }} icon={!!store.picProduct ? 'check' : 'camera'} onPress={() => takePicture('product')}>{!!store.picProduct && 'Retake'} Picture of Product serial number</MD3.Button>
                    <MD3.Button mode='outlined' style={{ borderRadius: 4, marginTop: 16 }} icon={!!store.picSheet ? 'check' : 'camera'} onPress={() => takePicture('sheet')}>{!!store.picSheet && 'Retake'} Installation Sheet</MD3.Button>
                    <MD3.Button onPress={saveCustomer} loading={loading} disabled={loading || !state.firstName || !store.defaultSelectedProduct || !store.selectedState || !purchaseDate || !installationDate || !store.picProduct || !store.picSheet || !state.serialNumber} mode='contained' style={{ marginTop: 16 }}>Save</MD3.Button>
                    <MD3.Button onPress={saveDraft} loading={loading} disabled={loading} mode='text' style={{ marginTop: 16 }}>Save Draft</MD3.Button>
                </View>
            </ScrollView>
            {loading && <Loader/>}
        </View>
    )
}