import * as React from 'react'
import { Alert, FlatList, Image, ScrollView, ToastAndroid, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Loader from '../../loader';
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, where } from 'firebase/firestore';
import { database } from '../../firebase-app';
import { ADMIN_EMAIL, SLABS } from '../../utils';
import axios from 'axios';
import { Octicons } from '@expo/vector-icons';
import { useState } from 'react';
import { useEffect } from 'react';
import { CSVLink } from 'react-csv';

export default function AdminViewWallet() {
    let [user, setUser] = React.useState({})
    let [redDialogVisible, setRedDialogVisible] = React.useState(false)
    let [loading, setLoading] = React.useState(false)
    let [redeemValue, setRedeemValue] = React.useState(0)
    let [history, setHistory] = useState([])
    let navigation = useNavigation();
    let store = useSelector(state => state.default)
    function hideRedDialog() {
        setRedDialogVisible(false)
    }
    async function loadUser() {
        setLoading(true)
        let _data = store.defaultTechnician
        let _user = await getDoc(doc(database, "users", _data.id));
        let _history = await getDocs(query(collection(database, "redeem_reqeusts"), where("requestedBy", "==", _data.id), orderBy('timestamp', 'desc')))
        console.log("Redeem count", _history.size, "for", _data.id)
        setHistory(_history.docs.map(e => {
            return {
                ...e.data(),
                id: e.id
            }
        }))
        setUser({
            ..._user.data(),
            id: _user.id
        })
        setLoading(false)
    }
    function selectTechnician() {
        navigation.navigate('list-technician')
    }
    useEffect(() => {
        if (!!store.defaultTechnician) {
            loadUser();
        }
    }, [store.defaultTechnician])

    let slab = SLABS.findSlab(user.points);
    let getHistoryData = () => {
        let _history = [...history];
        let points = 0;
        let naira = 0;
        _history.map(e => {
            if(e.status != "Approved")
                return;
            points = points + (+e.requestedPoints);
            naira = naira + (e.requestedPoints * 25)
        })
        let finalData = history.map((e, idx) => {
            return {
                'Serial No': idx+1,
                'Technician Name': e.user.fullName,
                'Date': format(e.timestamp.toDate(), 'd MMM, y'),
                'Payment Mode': e.paymentMode,
                'Points': e.requestedPoints,
                'Naira Value': e.requestedPoints * 25,
                'Status': e.status
            }
        })
        finalData.push({
            'Payment Mode': 'Total',
            'Points': points,
            'Naira Value': naira
        })
        return finalData
    }
    return (
        <View style={{ padding: 16, flex: 1 }}>
            <MD3.Card style={{ padding: 16 }} onPress={selectTechnician}>
                <MD3.Caption>Select technician</MD3.Caption>
                <MD3.Text>{store?.defaultTechnician?.fullName}</MD3.Text>
            </MD3.Card>
            {/* {user.points <= 50 && <MD3.Caption>Silver Member</MD3.Caption>}
            {(user.points >= 51 && user.points <= 250) && <MD3.Caption>Gold Member</MD3.Caption>}
            {(user.points >= 251 && user.points <= 500) && <MD3.Caption>Diamond Member</MD3.Caption>}
            {(user.points >= 501) && <MD3.Caption>Platinum Member</MD3.Caption>} */}
            <MD3.Caption>{slab?.label} Member</MD3.Caption>
            <MD3.Card style={{ marginVertical: 8, padding: 16 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {/* {user.points <= 50 && <Image source={require('../technician/silver.png')} style={{width: 48, height: 48}}/>}
                    {(user.points >= 51 && user.points <= 250) && <Image source={require('../technician/gold.png')} style={{width: 48, height: 48}}/>}
                    {(user.points >= 251 && user.points <= 500) && <Image source={require('../technician/diamond.png')} style={{width: 48, height: 48}}/>}
                    {(user.points >= 501) && <Image source={require('../technician/platinum.png')} style={{width: 48, height: 48}}/>} */}
                    {(slab?.label == 'Silver') && <Image source={require('../technician/silver.png')} style={{ width: 48, height: 48 }} />}
                    {(slab?.label == 'Gold') && <Image source={require('../technician/gold.png')} style={{ width: 48, height: 48 }} />}
                    {(slab?.label == 'Diamond') && <Image source={require('../technician/diamond.png')} style={{ width: 48, height: 48 }} />}
                    {(slab?.label == 'Platinum') && <Image source={require('../technician/platinum.png')} style={{ width: 48, height: 48 }} />}
                    <View style={{ flexDirection: 'row-reverse', flexGrow: 1, alignItems: 'baseline' }}>
                        <MD3.Text>&nbsp;Points</MD3.Text>
                        {/* <MD3.Text style={{fontWeight: 'bold', fontSize: 24}}>/{user.totalPoints || 0}</MD3.Text> */}
                        <MD3.Text style={{ fontWeight: 'bold', fontSize: 48 }}>{user.points || 0}</MD3.Text>
                    </View>
                </View>
            </MD3.Card>
            <MD3.Caption style={{ fontWeight: 'bold', marginVertical: 8 }}>REDEEM HISTORY ({history.length})</MD3.Caption>
            <FlatList
                data={history}
                keyExtractor={(item, index) => `h-${index}`}
                renderItem={({ item }) => {
                    return <MD3.Card style={{ padding: 16 }}>
                        <MD3.Caption>Points</MD3.Caption>
                        <MD3.Text>{item.requestedPoints} (₦{item.requestedPoints * 25})</MD3.Text>
                        <MD3.Caption>Payment Mode</MD3.Caption>
                        <MD3.Text>{item.paymentMode}</MD3.Text>
                        <MD3.Caption>Date</MD3.Caption>
                        <MD3.Text>{format(item.timestamp.toDate(), 'd MMM, y')}</MD3.Text>
                        <Octicons style={{ position: 'absolute', right: 0, top: 0 }} name="dot-fill" size={24} 
                        color={item.status === 'Approved' ? MD3.MD2Colors.greenA700 : item.status === 'Rejected' ? MD3.MD2Colors.redA700 : MD3.MD2Colors.amberA700} />
                    </MD3.Card>
                }} />
            <CSVLink data={getHistoryData()} target="_blank" filename='redemption-report'>
                <MD3.FAB icon={'download'} style={{ position: 'absolute', right: 16, bottom: 16 }} onPress={() => {

                }} />
            </CSVLink>
            {/* <CSVLink data={history} target="_blank" filename='points-approval-report'>
                <MD3.FAB icon={'alert-circle-check'} style={{ position: 'absolute', right: 96, bottom: 16 }} onPress={() => {

                }} />
            </CSVLink> */}
            {loading && <Loader />}
        </View>
    )
}