import * as React from 'react'
import { Alert, Image, ScrollView, ToastAndroid, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Camera } from 'expo-camera';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setPhoto } from '../../defaultSlice';
import Loader from '../../loader';
import { useState } from 'react';
import { ADMIN_EMAIL } from '../../utils';
import { useEffect } from 'react';
import axios from 'axios';

export default function Feedback() {
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [comment, setComment] = useState('')
    let [user, setUser] = React.useState({})
    let navigation = useNavigation()
    async function loadProfile(){
        let userData = await AsyncStorage.getItem('user')
        setUser(JSON.parse(userData));
    }
    useEffect(() => {
        loadProfile();
    }, [])
    async function submitFeedback(){
        setLoading(true)
        await axios.post('https://asia-south1-star-plus-battery.cloudfunctions.net/mail-util', {
            "to": ADMIN_EMAIL,
            "subject": "Star Plus Battery : New Feedback",
            "message": "<div>New Feedback submitted</div><table><tr><td>Name</td><td>"+user?.fullName+"</td></tr><tr><td>Email</td><td>"+user?.emailId+"</td></tr><tr><td>Phone</td><td>"+user?.username+"</td></tr><tr><td>Comment</td><td>"+comment+"</td></tr></table>"
        })
        setLoading(false);
        ToastAndroid.show('Feedback submitted', ToastAndroid.SHORT)
        navigation.goBack()
    }
    return (
        <View style={{flex: 1, padding: 16}}>
            <MD3.Card style={{padding: 16}}>
                <MD3.TextInput value={user?.fullName} onChangeText={setName} editable={false} placeholder={'Name'} label='Name' mode='outlined'></MD3.TextInput>
                <MD3.TextInput value={user?.emailId} editable={false} onChangeText={setEmail} keyboardType='email-address' label={'Email'} style={{marginTop: 8}} mode='outlined'></MD3.TextInput>
                <MD3.TextInput value={user?.username} editable={false} onChangeText={setPhone} keyboardType='phone-pad' maxLength={10} label={'Phone'} style={{marginTop: 8}} mode='outlined'></MD3.TextInput>
                <MD3.TextInput multiline defaultValue={comment} onChangeText={setComment} label='Comment, Concern or Suggestion' style={{marginTop: 8}} mode='outlined'></MD3.TextInput>
            </MD3.Card>
            <MD3.Button onPress={submitFeedback} disabled={!comment} mode='contained' style={{marginTop: 16}}>Submit Enquiry</MD3.Button>
            {loading && <Loader/>}
        </View>
    )
}