import { useFocusEffect, useNavigation } from '@react-navigation/native'
import * as react from 'react'
import { FlatList, ScrollView, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { collection, doc, setDoc, getDocs, query, where, orderBy } from "firebase/firestore";
import { database } from '../../firebase-app';
import Loader from '../../loader'
import { useDispatch } from 'react-redux';
import { setLga, setPhoto, setState } from '../../defaultSlice';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Octicons } from '@expo/vector-icons';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';


export default function AllTechnicians() {
    let [customers, setCustomers] = react.useState([])
    let navigation = useNavigation();
    let [loading, setLoading] = react.useState(false)
    let [showDisabled, setShowDisabled] = react.useState(false)
    let dispatcher = useDispatch();
    let [approvedSelected, setApprovedSelected] = react.useState(true)
    let [pendingApprovalSelected, setPendingApprovalSelected] = react.useState(true)
    function renderCustomer({ item }) {
        return (
            <MD3.Card style={{ padding: 16, marginTop: 8 }} onPress={() => viewTechnician(item)}>
                <MD3.Caption>Technician Name</MD3.Caption>
                <MD3.Text>{item.fullName}</MD3.Text>
                <MD3.Caption>{item.status}</MD3.Caption>
                <MD3.Caption>Contact</MD3.Caption>
                <MD3.Text>{item.phoneNumber}, {item.emailId}</MD3.Text>
                <MD3.Caption>Created on</MD3.Caption>
                <MD3.Text>{(item.__createdOn && format(item.__createdOn.toDate(), 'd MMM, y') || '--')}</MD3.Text>
                <Octicons style={{ position: 'absolute', right: 0, top: 0 }} name="dot-fill" size={24} color={item.disabled?MD3.MD2Colors.pinkA400:(item.status !== 'Approved' ? MD3.MD2Colors.amberA400 : MD3.MD2Colors.greenA700)} />
            </MD3.Card>
        )
    }
    async function loadCustomers() {
        setLoading(true)
        let docs = await getDocs(query(collection(database, "users"), where("type", "==", "Technician"), orderBy("__createdOn", "desc")))
        let _docs = [];
        docs.docs.forEach(doc => {
            let data = doc.data();
            _docs.push({
                ...data,
                id: doc.id
            })
            setCustomers(_docs)
        })
        setLoading(false)
    }
    function viewTechnician(item) {
        navigation.navigate('view-technician', {
            technician: item
        })
    }
    useFocusEffect(() => {
        console.log("useFocusEffect")
    })
    react.useEffect(() => {
        loadCustomers();
    }, [])
    let filteredCustomers = showDisabled?(customers.filter(e => {
        return !!e.disabled
    })):(customers.filter(e => {
        e.password = undefined;
        e.phoneNumber = e.username;
        e.username = undefined;
        return (e.status !== 'Approved' && pendingApprovalSelected) || (e.status === 'Approved' && approvedSelected)
    }))
    let pendingApprovalCount = customers.filter(e => {
        return e.status !== 'Approved'
    }).length
    let disbaledCount = customers.filter(e => {
        return !!e.disabled
    }).length
    let approvedCount = customers.filter(e => {
        return e.status === 'Approved'
    }).length
    return (
        <View style={{ flex: 1 }}>
            <View style={{ flex: 1, padding: 16 }}>
                    <View style={{ flexDirection: 'row', marginTop: 16, marginBottom: 8 }}>
                        <MD3.Chip disabled={showDisabled} selected={approvedSelected} onPress={() => setApprovedSelected(!approvedSelected)} style={{ flexGrow: 1 }}>Approved ({approvedCount})</MD3.Chip>
                        <View style={{ width: 16 }} />
                        <MD3.Chip disabled={showDisabled} selected={pendingApprovalSelected} onPress={() => setPendingApprovalSelected(!pendingApprovalSelected)} style={{ flexGrow: 1 }}>Pending approval ({pendingApprovalCount})</MD3.Chip>
                        <View style={{ width: 16 }} />
                        <MD3.Chip selected={showDisabled} onPress={() => setShowDisabled(!showDisabled)} style={{ flexGrow: 1 }}>{!showDisabled?'Show only disabled':'Show everything'} ({disbaledCount})</MD3.Chip>
                        <View style={{ width: 16 }} />
                        <MD3.Chip icon='delete' onPress={() => navigation.navigate('delete-technician')} style={{ width: 96 }}>Delete</MD3.Chip>
                    </View>
                    <View style={{flexGrow: 1}}>
                        <FlatList
                            data={filteredCustomers}
                            renderItem={renderCustomer}
                            ListEmptyComponent={() => (
                                <MD3.Caption style={{}}>No technicians available</MD3.Caption>
                            )}
                            keyExtractor={item => `customer-${item.id}`} />
                    </View>
            <CSVLink data={filteredCustomers.map(e => {
                return {
                    ...e,
                    __createdOn: e.__createdOn ? e.__createdOn.toDate() : ''
                }
            })} style={{position: 'fixed', right: 16, bottom: 16}} target="_blank" filename='technician-report'>
                <MD3.FAB icon={'download'} style={{ position: 'absolute', right: 16, bottom: 16 }} onPress={() => {

                }} />
            </CSVLink>
            </View>
            {loading && <Loader />}
        </View>
    )
}