import * as React from 'react'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import * as react from 'react'
import { FlatList, Image, ScrollView, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { collection, doc, setDoc, getDocs, query, where, orderBy, getDoc } from "firebase/firestore";
import { database } from '../../firebase-app';
import Loader from '../../loader'
import * as utils from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { setLga, setPhoto, setState, setProducts as setStoreProducts, setDefaultProduct, setApprovals } from '../../defaultSlice';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Octicons } from '@expo/vector-icons';
import { getImageUrl } from '../../utils';
// import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import axios from 'axios';
import { format } from 'date-fns'
import { CSVLink } from 'react-csv';

const Tab = createMaterialTopTabNavigator();

export default function () {
    let dispatch = useDispatch()
    let store = useSelector(state => state.default)
    let [activeApproval, setActiveApproval] = React.useState({})
    let [paymentMode, setPaymentMode] = React.useState('')
    let [actualPaymentMode, setActualPaymentMode] = React.useState('')
    let [accountCreationVisible, setAccountCreationVisible] = React.useState(false)
    let [loading, setLoading] = React.useState(false)
    let [showAccountsReview, setShowAccountsReview] = React.useState(false)
    function hideAccountsReview(){
        setShowAccountsReview(false)
    }
    async function fetchData() {
        setLoading(true)
        let approvals = await getDocs(query(collection(database, "redeem_reqeusts"), orderBy("timestamp", "asc")))
        dispatch(setApprovals(approvals.docs.map(e => {
            return {
                ...e.data(),
                id: e.id
            }
        })))
        setLoading(false)
        console.log("fetchData called", approvals.docs.length)
    }
    function approvalWorkflow(request) {
        setActiveApproval(request)
        setAccountCreationVisible(true)
    }
    function displayWorkflow(request) {
        setActiveApproval(request)
    }
    function hideDialog() {
        setAccountCreationVisible(false)
    }
    function dateFromSeconds(seconds) {
        let d = seconds.toDate();
        return format(d, 'h:mm a, d MMM,y')
    }
    async function reject() {
        setLoading(true);
        await setDoc(doc(database, "redeem_reqeusts", activeApproval.id), {
            ...activeApproval,
            status: 'Rejected'
        }, {
            merge: true
        })
        let _user = await getDoc(doc(database, "users", activeApproval.requestedBy))
        let _userData = _user.data();
        await utils.AUDITORS.auditPoints(_userData, 0, 'Withdrawal Rejected (Points:' + activeApproval.requestedPoints + ")", {})
        let emailMsg = `Hi ${_userData.fullName},<br/>Your redemption request is denied. Please contact the Star Plus Admin team for further details.<br/>Thanks,<br/>Team Star Plus`
        utils.sendEmail(_userData.emailId, 'Withdrawal rejected', emailMsg);

        //await deleteDoc(doc(database, "redeem_reqeusts", activeApproval.id))
        let approvals = await getDocs(query(collection(database, "redeem_reqeusts"), orderBy("timestamp", "asc")))
        dispatch(setApprovals(approvals.docs.map(e => {
            return {
                ...e.data(),
                id: e.id
            }
        })))
        setLoading(false)
        alert('Rejected!')
        hideDialog();
        await fetchData()
    }
    async function onApproveForReview(){
        try {
            setLoading(true);
            await setDoc(doc(database, "redeem_reqeusts", activeApproval.id), {
                ...activeApproval,
                status: 'Approved by accounts',
                // bankDetails: paymentMode,
                paymentMode: actualPaymentMode
            }, {
                merge: true
            })
            setAccountCreationVisible(false)
            await fetchData()
        }catch(e){
            alert('Cannot approve right now! Try again later.')
        }finally{
            setLoading(false)
        }
    }
    async function onApprove() {
        try {
            setLoading(true);
            await setDoc(doc(database, "redeem_reqeusts", activeApproval.id), {
                ...activeApproval,
                status: 'Approved',
                // bankDetails: paymentMode
            }, {
                merge: true
            })
            let _user = await getDoc(doc(database, "users", activeApproval.requestedBy))
            let _userData = _user.data();
            await setDoc(doc(database, "users", activeApproval.requestedBy), {
                points: _userData.points - activeApproval.requestedPoints
            }, {
                merge: true
            })
            let approvals = await getDocs(query(collection(database, "redeem_reqeusts"), orderBy("timestamp", "asc")))
            let emailMsg = `Hi ${_userData.fullName},<br/>Your redemption request is approved, please forward your account details to claim your reward..<br/>Thanks,<br/>Team Star Plus`
            utils.sendEmail(_userData.emailId, 'Withdrawal approved', emailMsg);

            dispatch(setApprovals(approvals.docs.map(e => {
                return {
                    ...e.data(),
                    id: e.id
                }
            })))
            let adminToken = await utils.getAdminToken()
            if (!!_userData.token) {
                //utils.sendPush(adminToken, "Withdrawal has been approved for " + activeApproval.requestedPoints + " points", "Withdrawal Approved")

            }
            await utils.AUDITORS.auditPoints(_userData, -activeApproval.requestedPoints, 'Withdrawal (' + paymentMode + ")", {})
            await axios.post('https://asia-south1-star-plus-battery.cloudfunctions.net/mail-util', {
                "to": activeApproval.user.emailId,
                "subject": "Star Plus Battery : Withdrawal Approved",
                "message": "<div>Approval of Redemption process is done. Please collect the amount from the Admin office</div>"
            })
            setLoading(false)
            setShowAccountsReview(false)
            await fetchData()
            alert('Request Approved by accounts!')
        } catch (e) {
            setLoading(false)
            console.error(e)
            alert('Cannot approve right now! Try again later.')
        }
    }
    let pendingApprovals = store.approvals.filter(e => e.status === "Pending")
    let approvedApprovals = store.approvals.filter(e => e.status === "Approved by accounts")
    function getApprovedData() {
        return approvedApprovals.map(e => {
            return {
                'Requested By': e.user.fullName,
                'Points': e.requestedPoints,
                'Naira Value': e.nairaValue,
                'Bank Account': e.bankDetails,
                'Status': e.status
            }
        })
    }
    function getPendingData() {
        return pendingApprovals.map(e => {
            return {
                'Requested By': e.user.fullName,
                'Points': e.requestedPoints,
                'Naira Value': e.nairaValue,
                'Bank Account': e.bankDetails,
                'Status': e.status
            }
        })
    }
    React.useEffect(() => {
        fetchData()
    }, [])
    function PendingRequests() {
        return (
            <View style={{flex: 1}}>
                <FlatList
                    data={pendingApprovals}
                    keyExtractor={item => item.id}
                    showsVerticalScrollIndicator={false}
                    renderItem={({ item }) => (
                        <View key={item.id}>
                            <MD3.Card style={{ padding: 16 }} onPress={() => approvalWorkflow(item)}>
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <View>
                                        <MD3.Text style={{ fontWeight: 'bold' }}>{item.user.fullName}</MD3.Text>
                                        <MD3.Caption>{dateFromSeconds(item.timestamp)}</MD3.Caption>
                                    </View>
                                    <View style={{ alignItems: 'flex-end' }}>
                                        <MD3.Title>{item.requestedPoints}</MD3.Title>
                                        <MD3.Caption>₦{item.nairaValue}</MD3.Caption>
                                    </View>
                                </View>
                            </MD3.Card>
                            <MD3.Divider />
                        </View>
                    )}
                />
                <CSVLink data={getPendingData()} target="_blank" filename='redemption-report'>
                    <MD3.FAB icon={'download'} style={{ position: 'absolute', right: 16, bottom: 16 }} onPress={() => {

                    }} />
                </CSVLink>
            </View>
        )
    }

    function ApprovedReqeusts() {
        return (
            <View style={{flex: 1}}>
                <FlatList
                    data={approvedApprovals}
                    keyExtractor={item => item.id}
                    showsVerticalScrollIndicator={false}
                    renderItem={({ item }) => (
                        <View key={item.id}>
                            <MD3.Card style={{ padding: 16 }} onPress={() => {
                                setActiveApproval(item)
                                setShowAccountsReview(true)
                            }}>
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <View>
                                        <MD3.Text style={{ fontWeight: 'bold' }}>{item.user.fullName}</MD3.Text>
                                        <MD3.Caption>{dateFromSeconds(item.timestamp)}</MD3.Caption>
                                    </View>
                                    <View style={{ alignItems: 'flex-end' }}>
                                        <MD3.Title>{item.requestedPoints}</MD3.Title>
                                        <MD3.Caption>₦{item.nairaValue}</MD3.Caption>
                                    </View>
                                </View>
                            </MD3.Card>
                            <MD3.Divider />
                        </View>
                    )}
                />
                <CSVLink data={getApprovedData()} target="_blank" filename='redemption-report'>
                    <MD3.FAB icon={'download'} style={{ position: 'absolute', right: 16, bottom: 16 }} onPress={() => {

                    }} />
                </CSVLink>
            </View>
        )
    }
    return (
        <View style={{flex: 1}}>

            <Tab.Navigator>
                <Tab.Screen name="Pending Requests" component={PendingRequests} />
                <Tab.Screen name="Approved by Accounts" component={ApprovedReqeusts} />
            </Tab.Navigator>
            <MD3.FAB icon={'refresh'} style={{ position: 'absolute', left: 16, bottom: 16 }} onPress={fetchData} />
            <MD3.Dialog visible={accountCreationVisible} onDismiss={hideDialog} dismissable={true} style={{ backgroundColor: 'white' }}>
                <MD3.Dialog.Title>
                    Pending Approval
                </MD3.Dialog.Title>
                <MD3.Dialog.Content>
                    <MD3.Caption>
                        Technician
                    </MD3.Caption>
                    <MD3.Text>{activeApproval.user?.fullName}</MD3.Text>
                    <MD3.Caption>
                        Requested Points
                    </MD3.Caption>
                    <MD3.Text>{activeApproval.requestedPoints}</MD3.Text>
                    <MD3.Caption>
                        Naira Value
                    </MD3.Caption>
                    <MD3.Text>₦{activeApproval.nairaValue}</MD3.Text>
                    {/* <MD3.Caption>
                        Bank Account Details
                    </MD3.Caption>
                    <MD3.TextInput value={paymentMode} onChangeText={setPaymentMode} label={'Enter bank account details'} mode='outlined' dense /> */}
                </MD3.Dialog.Content>
                <MD3.Dialog.Actions>
                    <MD3.Button disabled={loading} onPress={reject}>&nbsp;Deny&nbsp;</MD3.Button>
                    <MD3.Button onPress={() => {
                        onApproveForReview();
                    }} mode='contained' icon='lock' disabled={loading} loading={loading} labelStyle={{ marginHorizontal: 16 }}>&nbsp;Approve for Accounts&nbsp;</MD3.Button>
                </MD3.Dialog.Actions>
            </MD3.Dialog>

            {/* Accounts Review */}
            <MD3.Dialog visible={showAccountsReview} onDismiss={hideAccountsReview} dismissable={true} style={{ backgroundColor: 'white' }}>
                <MD3.Dialog.Title>
                    Approve Redemption
                </MD3.Dialog.Title>
                <MD3.Dialog.Content>
                    <MD3.Caption>
                        Technician
                    </MD3.Caption>
                    <MD3.Text>{activeApproval.user?.fullName}</MD3.Text>
                    <MD3.Caption>
                        Requested Points
                    </MD3.Caption>
                    <MD3.Text>{activeApproval.requestedPoints}</MD3.Text>
                    <MD3.Caption>
                        Naira Value
                    </MD3.Caption>
                    <MD3.Text>₦{activeApproval.nairaValue}</MD3.Text>
                    {/* <MD3.Caption>
                        Bank Account Details
                    </MD3.Caption>
                    <MD3.TextInput value={activeApproval.bankDetails} editable={true} onChangeText={setPaymentMode} label={'Enter bank account details'} mode='outlined' dense /> */}
                    <MD3.TextInput value={actualPaymentMode} editable={true} onChangeText={setActualPaymentMode} label={'Enter payment mode'} mode='outlined' dense />
                </MD3.Dialog.Content>
                <MD3.Dialog.Actions>
                    {/* <MD3.Button disabled={loading} onPress={reject}>&nbsp;Deny&nbsp;</MD3.Button> */}
                    <MD3.Button onPress={() => {
                        onApprove();
                    }} mode='contained' icon='lock' disabled={loading} loading={loading} labelStyle={{ marginHorizontal: 16 }}>&nbsp;Approved &amp; Paid&nbsp;</MD3.Button>
                </MD3.Dialog.Actions>
            </MD3.Dialog>
            {loading && <Loader/>}
        </View>
    )
}