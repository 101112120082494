import * as React from 'react'
import { FlatList, ScrollView, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { useState } from 'react';
import { addDays, endOfDay, format, isAfter, isBefore, isEqual, parse, startOfDay, subDays, subMinutes } from 'date-fns';
import Loader from '../../loader';
import { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomers, setDefaultTechnician, setLga, setState } from '../../defaultSlice';
import { collection, doc, getDocs, orderBy, query, Timestamp, where, writeBatch, deleteDoc } from 'firebase/firestore';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { database } from '../../firebase-app';
import { AntDesign } from '@expo/vector-icons';
import { CSVLink } from 'react-csv';

export default function DeleteTechnician() {
    let [loading, setLoading] = useState(false)
    let [customers, setCustomers] = useState([])
    async function loadTechnicians() {
        setLoading(true)
        let docs = await getDocs(query(collection(database, "users"), where("type", "==", "Technician"), orderBy("__createdOn", "desc")))
        let _docs = [];
        docs.docs.forEach(doc => {
            let data = doc.data();
            _docs.push({
                ...data,
                id: doc.id,
                checked: 'unchecked'
            })
            setCustomers(_docs)
        })
        setLoading(false)
    }
    function toggleCustomer(customer) {
        const updatedCustomers = customers.map(c => {
            if (c.id === customer.id) {
                return {
                    ...c,
                    checked: c.checked === 'checked' ? 'unchecked' : 'checked'
                };
            }
            return c;
        });
        setCustomers(updatedCustomers);
    }
    async function deleteDocs() {
        let deleteCustomers = customers.filter(e => e.checked === 'checked');
        if(!confirm("Do you want to delete\n"+deleteCustomers.map(e => e.fullName + "\n") + "permanently from the system?")){
            return;
        }
        try{
            setLoading(true)
            let ids = deleteCustomers.map(e => e.id)
            console.log(ids)
            ids.map(id => {
                deleteDoc(doc(database, "users", id))
            })
        }catch(e){
            console.log(e)
            alert("Deletion failed. Contact admin")
        }finally{
            setLoading(false)
            await loadTechnicians()
        }
    }
    const hasSelected = customers.filter(e => e.checked === 'checked').length > 0
    useEffect(() => {
        loadTechnicians()
    }, [])
    return (
        <View style={{ flex: 1, padding: 16 }}>
            <MD3.Headline>Delete Technicians</MD3.Headline>
            <ScrollView style={{ flex: 1 }}>
                {customers.map(e => (
                    <View key={e.id} style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <MD3.Checkbox onPress={() => toggleCustomer(e)} status={e.checked}/>
                        <View>
                            <MD3.Text>{e.fullName}</MD3.Text>
                            <MD3.Caption>{e.username} | {e.emailId}</MD3.Caption>
                        </View>
                    </View>
                ))}
            </ScrollView>
            {hasSelected && <MD3.FAB icon={'delete'} style={{ position: 'absolute', right: 16, bottom: 16 }} onPress={() => {
                deleteDocs()
            }} />}
            {loading && <Loader />}
        </View>
    )
}