import { uuidv4 } from '@firebase/util'
import { useNavigation } from '@react-navigation/native'
import { collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore'
import * as React from 'react'
import { Linking, ScrollView, ToastAndroid, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { KeyboardAvoidingView } from 'react-native'
import { database } from '../../firebase-app'
import { TouchableOpacity } from 'react-native'
import axios from 'axios'
import * as utils from '../../utils'
import { ADMIN_EMAIL, validPhoneNumber } from '../../utils'
import { FontAwesome } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage'
import otpGenerators from 'otp-generators'

export default function MemberRegistration() {
    let [fullName, setFullName] = React.useState('')
    let [firstName, setFirstName] = React.useState('')
    let [lastName, setLastName] = React.useState('')
    let [phoneNumber, setPhoneNumber] = React.useState('')
    let [referralCode, setReferralCode] = React.useState('')
    let [emailId, setEmailId] = React.useState('')
    let navigation = useNavigation();
    let [loading, setLoading] = React.useState(false)
    let [otpSent, setOtpSent] = React.useState(false)
    let [otp, setOtp] = React.useState('')
    let [otpVerified, setOtpVerified] = React.useState()
    let [password, setPassword] = React.useState('')
    let [registeredUserId, setRegisteredUserId] = React.useState('')
    let [policy1, setPolicty1] = React.useState(false)
    let [policy2, setPolicty2] = React.useState(false)
    let [generatedOtp, setGeneratedOtp] = React.useState('')
    let [secureTextEntry, setSecureTextEntry] = React.useState(true)
    let [otpTime, setOtpTime] = React.useState(-1)
    let [accountCreated, setAccountCreated] = React.useState(false)
    //let [userId, setUserId] = React.useState(null)
    var newOTP = require('otp-generators')
    let intervalId;

    React.useEffect(() => {
        setFullName(`${firstName} ${lastName}`)
    }, [firstName, lastName])

    const setupTimer = () => {
        intervalId = setInterval(() => {
            setOtpTime(otpTime => otpTime >= 0 ? otpTime - 1 : -1)
            // if(otpTime > 0){
            //     console.log("Setting OTP to", otpTime - 1)
            //     setOtpTime(otpTime => otpTime - 1)
            // }else if(otpTime == 0){
            //     setOtpTime(-1);
            //     setOtpSent(false)
            // }
        }, 1000)
    }

    React.useEffect(() => {
        console.log("OTP time", otpTime)
        if(otpTime === 0){
            setOtp('')
            setOtpSent(false)
        }
    }, [otpTime])
    React.useEffect(() => {
        setupTimer();
        return () => {
            console.log("Clear timeout")
            clearInterval(intervalId)
        }
    }, [])

    async function onRegister() {
        if(!validPhoneNumber(phoneNumber)){
            ToastAndroid.show('Invalid phone number', ToastAndroid.SHORT)
            return;
        }
        setLoading(true)
        try{
            let _userId = uuidv4();
            let _otp = newOTP.generate(6, { alphabets: false, upperCase: false, specialChar: false })
            if(!accountCreated){
                setRegisteredUserId(_userId)
                let existingUsers = await getDocs(query(collection(database, "users"), where("emailId", "==", emailId)))
                if(existingUsers.size != 0){
                    setLoading(false)
                    ToastAndroid.show('Email already registered', ToastAndroid.SHORT)
                    return;
                }
                existingUsers = await getDocs(query(collection(database, "users"), where("username", "==", phoneNumber)))
                if(existingUsers.size != 0){
                    setLoading(false)
                    ToastAndroid.show('Phone Number already registered', ToastAndroid.SHORT)
                    return;
                }
                let code = otpGenerators.generate(6, {
                    alphabets: true,
                    uppercase: true
                }).toUpperCase()
                await setDoc(doc(database, "users", _userId), {
                    fullName: fullName,
                    firstName: firstName,
                    lastName: lastName,
                    username: phoneNumber,
                    password: '',
                    referral: code,
                    emailId: emailId.toLowerCase(),
                    verified: false,
                    referralCode: referralCode,
                    type: 'Technician',
                    points: 0,
                    __createdOn: new Date()
                })
                setAccountCreated(true)
            }            
            await axios.post('https://asia-south1-star-plus-battery.cloudfunctions.net/mail-util', {
                "to": emailId,
                "subject": "Star Plus Battery : Login OTP",
                "message": "<div>Your login OTP is:</div><p><h3>" + _otp + "</h3></p>"
            })
            setGeneratedOtp(_otp)
            setLoading(false)
            setOtpSent(true)
            setOtpTime(120)
            ToastAndroid.show('OTP sent to email', ToastAndroid.SHORT)
        }catch(e){
            console.error(e)
            setLoading(false)
            ToastAndroid.show('Failed to register your profile. Please contact administrator', ToastAndroid.SHORT)
        }
        //navigation.goBack();
    }
    async function verifyOtp() {
        if (otp == generatedOtp) {
            setLoading(true)
            await setDoc(doc(database, "users", registeredUserId), {
                verified: true
            }, {
                merge: true
            })
            setLoading(false)
            setOtpTime(-1)
            setOtpVerified(true)
            ToastAndroid.show('OTP verified successfully', ToastAndroid.SHORT)
        } else {
            ToastAndroid.show('Invalid OTP entered', ToastAndroid.SHORT)
        }
    }
    async function updatePassword() {
        console.log("Updating password")
        setLoading(true)
        await setDoc(doc(database, "users", registeredUserId), {
            password: password
        }, {
            merge: true
        })
        setLoading(false)
        ToastAndroid.show('Password updated!', ToastAndroid.SHORT)
        let adminToken = await utils.getAdminToken()
        if(!!adminToken){
            utils.sendPush(adminToken, "A new user " + fullName + " has registered!", "New Registration!")
        }
        await axios.post('https://asia-south1-star-plus-battery.cloudfunctions.net/mail-util', {
            "to": ADMIN_EMAIL,
            "subject": "Star Plus Battery : New User",
            "message": "<div>New User registered</div><div>Name: " + fullName + "</div><br/>Please approve!"
        })
        ToastAndroid.show("Registration Successfully Done", ToastAndroid.SHORT)
        setLoading(true)
        let _user = await getDoc(doc(database, "users", registeredUserId));
        let userData = {
            ..._user.data(),
            id: _user.id
        }
        await AsyncStorage.setItem('user', JSON.stringify(userData))
        let token = await AsyncStorage.getItem('token')
        if (!!token) {
            await setDoc(doc(database, "users", registeredUserId), {
                token: token
            }, {
                merge: true
            })
        }
        setLoading(false)
        navigation.goBack()
        navigation.replace('tenician-dashboard')
        //navigation.goBack()
    }
    function formatSeconds(){
        let minutes = parseInt(otpTime/60)
        let seconds = otpTime%60;
        if(minutes>0){
            return minutes+"m " + seconds+"s"
        }else{
            return seconds+"s"
        }
    }
    let buttonDisabled = !firstName || !lastName || !emailId || !phoneNumber || (otpSent && !otp) || (otpVerified && !password)
    return (
        <ScrollView style={{ margin: 16, flex: 1, flexGrow: 1 }}>
            <MD3.Card style={{ flex: 1 }}>
                <View style={{ padding: 16, borderRadius: 4, flexGrow: 1 }}>
                    {/* <MD3.TextInput disabled={loading || otpSent} editable={!loading} defaultValue={fullName} onChangeText={setFullName} label={'Full Name'} mode='outlined' /> */}
                    <MD3.TextInput disabled={loading || otpSent} editable={!loading} defaultValue={firstName} onChangeText={setFirstName} label={'First Name'} mode='outlined' />
                    <MD3.TextInput disabled={loading || otpSent} editable={!loading} defaultValue={lastName} style={{marginTop: 8}} onChangeText={setLastName} label={'Last Name'} mode='outlined' />
                    <View style={{ marginTop: 8 }}>
                        <MD3.TextInput disabled={loading || otpSent} editable={!loading} defaultValue={phoneNumber} 
                            onChangeText={setPhoneNumber} style={{paddingStart: 24+16}} maxLength={10} label={'Phone Number'} mode='outlined' keyboardType='phone-pad' />
                        <MD3.Text style={{position: 'absolute', fontSize: 15.5, left: 0, paddingVertical: 21, paddingStart: 16}}>+234</MD3.Text>
                    </View>
                    <MD3.TextInput disabled={loading || otpSent} editable={!loading} defaultValue={emailId} onChangeText={setEmailId} label={'Email ID'} style={{ marginTop: 8 }} mode='outlined' keyboardType='email-address' />
                    <MD3.TextInput disabled={loading || otpSent} editable={!loading} defaultValue={referralCode} onChangeText={setReferralCode} label={'Referral Code'} style={{ marginTop: 8 }} mode='outlined' />
                    {otpSent && <MD3.TextInput disabled={loading || otpVerified} editable={!loading} defaultValue={otp} onChangeText={setOtp} label={'Enter OTP'} style={{ marginTop: 8 }} mode='outlined' maxLength={6} />}
                    {otpVerified && <MD3.TextInput disabled={loading} editable={!loading} defaultValue={password} onChangeText={setPassword} label={'Enter new password'} secureTextEntry={secureTextEntry} style={{ marginTop: 8 }} mode='outlined' maxLength={20}
                        right={<MD3.TextInput.Icon icon={secureTextEntry?'eye-off':'eye'} onPress={() => setSecureTextEntry(!secureTextEntry)} />} />}
                    <View style={{ flexGrow: 1 }} />
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 8 }}>
                        <MD3.Checkbox
                            status={policy1 ? 'checked' : 'unchecked'}
                            onPress={() => {
                                setPolicty1(!policy1)
                            }}
                        />
                        <View style={{ flexDirection: 'row' }}>
                            <MD3.Text>I agree to the </MD3.Text>
                            <TouchableOpacity onPress={() => {
                                Linking.openURL('https://www.keepandshare.com/doc10/35725/terms-conditions-pdf-164k?da=y');
                            }}>
                                <MD3.Text style={{ color: 'blue' }}>Terms and Conditions</MD3.Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <MD3.Checkbox
                            status={policy2 ? 'checked' : 'unchecked'}
                            onPress={() => {
                                setPolicty2(!policy2)
                            }}
                        />
                        <View style={{ flexDirection: 'row' }}>
                            <MD3.Text>I agree to the </MD3.Text>
                            <TouchableOpacity onPress={() => {
                                Linking.openURL('https://www.keepandshare.com/doc10/35724/privacy-policy-pdf-110k?da=y');
                            }}>
                                <MD3.Text style={{ color: 'blue' }}>Privacy Policy</MD3.Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>

                <MD3.Button loading={loading} disabled={!policy1 || !policy2 || loading || buttonDisabled} onPress={() => {
                    if (otpSent) {
                        if (!otpVerified) {
                            verifyOtp()
                        } else {
                            updatePassword()
                        }
                    } else {
                        onRegister()
                    }
                }} mode='contained' style={{ marginHorizontal: 16, marginBottom: 8 }}>{otpSent ? (otpVerified ? "Register Profile" : "Verify Account") : ("Send OTP")}</MD3.Button>
                {(otpTime>0 && !otpVerified) > 0 && <MD3.Caption style={{textAlign: 'center', paddingBottom: 8}}>Resend in {formatSeconds()} minutes</MD3.Caption>}
                <View style={{height: 8}}/>
            </MD3.Card>
        </ScrollView>
    )
}