import * as React from 'react'
import { Linking, ScrollView, ToastAndroid, useWindowDimensions, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation, useRoute } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { database } from '../firebase-app';
import { doc, setDoc } from 'firebase/firestore';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Camera, CameraType } from 'expo-camera';
import { manipulateAsync, FlipType, SaveFormat } from 'expo-image-manipulator';
import * as ImagePicker from "expo-image-picker";
import { getStorage, ref, uploadBytes, uploadString } from "firebase/storage";
import { setPhoto } from '../defaultSlice';
const storage = getStorage();

export default function TakePictureComponent() {
    const [permission, requestPermission] = Camera.useCameraPermissions();
    let dimension = useWindowDimensions();
    let dispatch = useDispatch();
    let navigation = useNavigation();
    let route = useRoute();
    let [loading, setLoading] = React.useState(false)
    let camera = React.useRef();
    React.useEffect(() => {
        if(!permission?.granted){
            requestPermission().then(() => {
                ImagePicker.requestMediaLibraryPermissionsAsync();
            });
        }
    }, []);
    async function onPictureSaved(photo){
        try{
            camera?.current?.pausePreview();
            let height = photo.height;
            let width = photo.width;
            let expectedHeight = Math.min(1080, height);
            let expectedWidth = (width/height) * expectedHeight;
            let image = await manipulateAsync(photo.uri, [{
                resize: {
                    width: expectedWidth,
                    height: expectedHeight
                }
            }], {
                compress: 0.75,
                base64: true
            })
            console.log("--------------------------------")
            let storageRef = ref(storage, route.params.type+'/'+uuidv4()+".jpg");
            let f = await fetch(image.uri)
            let blob = await f.blob()
            let snapshot = await uploadBytes(storageRef, blob)
            dispatch(setPhoto({
                type:  route.params.type,
                url: snapshot.metadata.fullPath
            }))
            console.log("Updated", route.params.type,"with", snapshot.metadata.fullPath)
            setLoading(false)
            navigation.goBack();
        }catch(e){
            ToastAndroid.show('Cannot upload image. Try again later', ToastAndroid.SHORT)
        }
    }
    function captureImage(){
        if(!loading){
            setLoading(true)
            camera.current?.takePictureAsync({
                onPictureSaved: onPictureSaved
            })
        }
    }
    return (
        <View style={{flex: 1}}>
            <Camera ref={camera} style={{width: dimension.width, flexGrow: 1}} type={!!route.params.front ? CameraType.front : CameraType.back} pictureSize='' />
            <MD3.FAB loading={loading} icon={'camera'} style={{position: 'absolute', bottom: 16, alignSelf: 'center'}} onPress={captureImage}/>
        </View>
    )
}